import { Card, FlexCol, FlexRow, SVGWrapper, Typography } from "@shared";
import { ScrollContainers } from "./ScrollContainers";
import { Header } from "../table/Header";
import { DataContainer } from "../table/DataContainer";
import { AuditedBy } from "../../../../components/auditedBy/AuditedBy";
import { ArrakisTableHeader } from "../table/arrakis/ArrakisTableHeader";

//ICONS

import ETH from "@assets/tokens/eth.svg";
import LiquidityIcon from "@assets/layout/drop.svg";

/* ----------- */
/* Illustration*/
/* ----------- */

import StUscEthIllustration from "@assets/illustrations/stUscEthIllustration.svg";
import BoostedUscEthIllustration from "@assets/illustrations/boostedUscEthIllustration.svg";
import StChiEthIllustration from "@assets/illustrations/stChiEthIllustration.svg";
import BoostedChiEthIllustration from "@assets/illustrations/boostedChiEthIllustration.svg";
import BoostedWstUscIllustration from "@assets/illustrations/boostedWstUscillustration.svg";

import MstUscEthIllustration from "@assets/illustrations/mStUscEthIllustration.svg";
import MboostedUscEthIllustration from "@assets/illustrations/mBoostedUscEthIllustration.svg";
import MStChiEthIllustration from "@assets/illustrations/mStChiEthIllustration.svg";
import MBoostedChiEthIllustration from "@assets/illustrations/mBoostedChiEthIllustration.svg";
import MBoostedWstUscIllustration from "@assets/illustrations/mBoostedWstUscIllustration.svg";

import { MobileScrollContainers } from "./MobileScrollContainers";
import { useFetchUscEthLpDetailedAPR } from "../../../../state/data-provider/useFetchUscEthLpDetailedAPR";
import { useFetchChiEthLpDetailedAPR } from "../../../../state/data-provider/useFetchChiEthLpDetailedAPR";
import { useFetchBoostedUscEthApr } from "../../../../state/data-provider/useFetchBoostedUscEthApr";
import { useFetchBoostedChiEthApr } from "../../../../state/data-provider/useFetchBoostedChiEthApr";
import { useFetchBoostedWstUscApr } from "../../../../state/data-provider/useFetchBoostedWstUscApr";
export interface ScrollItem {
  id: string;
  title: string;
  apr: string;
  subtitle: string;
  icon: string;
  illustration: string;
  mobileIllustration?: string;
  isFetched: boolean;
  activeTab: string;
  activeDropdown?: string;
  stakedSelectedItem: string;
  boostSelectedItem: string;
}

export const Main = () => {
  const { detailedApr: uscEthApr, isFetchedAPR: isUscEthAprFetched } =
    useFetchUscEthLpDetailedAPR();
  const { detailedApr: chiEthApr, isFetchedAPR: isChiEthFetched } =
    useFetchChiEthLpDetailedAPR();

  const {
    totalApr: totalBoostedUscEthApr,
    isFetchedAPR: isBoostedUscEthAprFetched,
  } = useFetchBoostedUscEthApr();

  const {
    totalApr: totalBoostedChiEthApr,
    isFetchedAPR: isBoostedChiEthAprFetched,
  } = useFetchBoostedChiEthApr();

  const {
    totalApr: totalBoostedWstUscApr,
    isFetchedAPR: isBoostedWstUscAprFetched,
  } = useFetchBoostedWstUscApr();

  const items: ScrollItem[] = [
    {
      id: "1",
      title: "Earn up to",
      apr: uscEthApr.totalApr.formatted,
      subtitle: "Staked USC/ETH on CHI",
      icon: ETH,
      illustration: StUscEthIllustration,
      mobileIllustration: MstUscEthIllustration,
      isFetched: isUscEthAprFetched,
      activeTab: "stake",
      stakedSelectedItem: "uscEth",
      boostSelectedItem: "stUscEth",
    },
    {
      id: "2",
      title: "Earn up to",
      apr: totalBoostedUscEthApr.formatted,
      subtitle: "Boosted USC/ETH on CHI",
      icon: ETH,
      illustration: BoostedUscEthIllustration,
      mobileIllustration: MboostedUscEthIllustration,
      isFetched: isBoostedUscEthAprFetched,
      activeTab: "boost",
      stakedSelectedItem: "uscEth",
      boostSelectedItem: "stUscEth",
    },
    {
      id: "3",
      title: "Earn up to",
      apr: chiEthApr.totalApr.formatted,
      subtitle: "Staked CHI/ETH on CHI",
      icon: ETH,
      illustration: StChiEthIllustration,
      mobileIllustration: MStChiEthIllustration,
      isFetched: isChiEthFetched,
      activeTab: "stake",
      stakedSelectedItem: "chiEth",
      boostSelectedItem: "stChiEth",
    },
    {
      id: "4",
      title: "Earn up to",
      apr: totalBoostedChiEthApr.formatted,
      subtitle: "Boosted CHI/ETH on CHI",
      icon: ETH,
      illustration: BoostedChiEthIllustration,
      mobileIllustration: MBoostedChiEthIllustration,
      isFetched: isBoostedChiEthAprFetched,
      activeTab: "boost",
      stakedSelectedItem: "chiEth",
      boostSelectedItem: "stChiEth",
    },
    // {
    //   id: "5",
    //   title: "Earn up to",
    //   apr: "33.23",
    //   subtitle: "Staked USC on CHI",
    //   icon: ETH,
    //   illustration: stUscIllustration,
    //   mobileIllustration: stUscIllustration,
    //   isFetched: isStUscAprFetched
    // },
    {
      id: "6",
      title: "Earn up to",
      apr: totalBoostedWstUscApr.formatted,
      subtitle: "Boosted wstUSC on CHI",
      icon: ETH,
      illustration: BoostedWstUscIllustration,
      mobileIllustration: MBoostedWstUscIllustration,
      isFetched: isBoostedWstUscAprFetched,
      activeTab: "boost",
      stakedSelectedItem: "chiEth",
      boostSelectedItem: "wstUsc",
    },
  ];

  return (
    <Card hasBorder className="overflow-hidden">
      <FlexCol className="w-full">
        <FlexCol className="gap-6">
          <FlexCol className="px-4 pb-4 sm:px-8 pt-8 gap-6 bg-white">
            <FlexCol className="gap-4">
              <FlexRow className="items-center gap-4">
                <Typography type="h2" className="text-button-selection-stroke">
                  DeFi Opportunities
                </Typography>
                <SVGWrapper
                  src={LiquidityIcon}
                  width={32}
                  height={32}
                  color="#0051A6"
                />
                <div className="hidden 2xl:block">
                  <AuditedBy />
                </div>
              </FlexRow>
              <div className="block 2xl:hidden w-[156px]">
                <AuditedBy />
              </div>
              <Typography
                type="body-medium-regular"
                className="text-neutral-black-60"
              >
                Provide liquidity across DeFi and earn more with your USC, stUSC
                and CHI
              </Typography>
              {/* Desktop verzija */}
              <FlexCol className="hidden w-full 2xl:block">
                <ScrollContainers
                  items={items.map((item) => ({
                    ...item,
                    isFetched: item.isFetched,
                    illustration: item.illustration,
                  }))}
                />
              </FlexCol>

              <FlexCol className="w-full 2xl:hidden relative rounded-xl">
                <div className="absolute rounded-xl top-0 left-0 w-full h-12 bg-gradient-to-b from-gray-200 to-transparent z-10 pointer-events-none" />

                <div className="absolute rounded-xl bottom-0 left-0 w-full h-12 bg-gradient-to-t from-gray-200 to-transparent z-10 pointer-events-none" />

                <MobileScrollContainers
                  items={items.map((item) => ({
                    ...item,
                    isFetched: item.isFetched,
                    illustration: item.mobileIllustration || item.illustration,
                  }))}
                />
              </FlexCol>

              <div className="flex flex-col gap-2">
                <div className="hidden md:block">
                  <Header />
                </div>

                <DataContainer />
                <div className="hidden md:block">
                  <ArrakisTableHeader />
                </div>
              </div>
            </FlexCol>
          </FlexCol>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};

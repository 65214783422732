import React, { createContext, useContext, useState } from "react";

type StakeContextType = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  stakeSelectedItem: string | null;
  setStakeSelectedItem: (item: string | null) => void;
  unstakeSelectedItem: string | null;
  setUnstakeSelectedItem: (item: string | null) => void;
  boostSelectedItem: string | null;
  setBoostSelectedItem: (item: string | null) => void;
  withdrawSelectedItem: string | null;
  setWithdrawSelectedItem: (item: string | null) => void;
};

const StakeContext = createContext<StakeContextType | undefined>(undefined);

export const StakeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeTab, _setActiveTab] = useState<string>("stake");
  const setActiveTab = (tab: string) => {
    _setActiveTab(tab);
  };
  const [stakeSelectedItem, setStakeSelectedItem] = useState<string | null>(
    "uscEth"
  );
  const [unstakeSelectedItem, setUnstakeSelectedItem] = useState<string | null>(
    "uscEth"
  );
  const [boostSelectedItem, setBoostSelectedItem] = useState<string | null>(
    "stUscEth"
  );
  const [withdrawSelectedItem, setWithdrawSelectedItem] = useState<
    string | null
  >("stUscEth");

  return (
    <StakeContext.Provider
      value={{
        activeTab,
        setActiveTab,
        stakeSelectedItem,
        setStakeSelectedItem,
        unstakeSelectedItem,
        setUnstakeSelectedItem,
        boostSelectedItem,
        setBoostSelectedItem,
        withdrawSelectedItem,
        setWithdrawSelectedItem,
      }}
    >
      {children}
    </StakeContext.Provider>
  );
};

export const useStakeContext = (): StakeContextType => {
  const context = useContext(StakeContext);
  if (!context) {
    throw new Error("useStakeContext must be used within a StakeProvider");
  }
  return context;
};

import { AccessControl, FlexCol } from "@shared";
import { UpdateEpochChiEth } from "./UpdateEpochChiEth";
import { UpdateEpochUscEth } from "./UpdateEpochUscEth";
import { UpdateEpochWstEth } from "./UpdateEpochWstEth";
import { contractAddresses, ownerWhiteListedAddresses } from "@meta";
import { UpdateRewardsPerEpoch } from "./UpdateRewardsPerEpoch";

export default function LockingManagerPage() {
  return (
    <AccessControl allowedAddresses={ownerWhiteListedAddresses}>
      <FlexCol className="gap-10">
        <div className="w-2/3">
          <UpdateRewardsPerEpoch
            address={contractAddresses.WST_USC_Locking}
            name="wstUSC-Locking"
          />
        </div>
        <div className="w-2/3">
          <UpdateRewardsPerEpoch
            address={contractAddresses.CHI_ETH_LP_Locking}
            name="CHI-ETH-LP-Locking"
          />
        </div>
        <div className="w-2/3">
          <UpdateRewardsPerEpoch
            address={contractAddresses.USC_ETH_LP_Locking}
            name="USC-ETH-LP-Locking"
          />
        </div>
        <div className="w-2/3">
          <UpdateEpochChiEth />
        </div>
        <div className="w-2/3">
          <UpdateEpochUscEth />
        </div>
        <div className="w-2/3">
          <UpdateEpochWstEth />
        </div>
      </FlexCol>
    </AccessControl>
  );
}

import { formatMoney } from "@utils";
import { formatUnits } from "viem";
import { useFetchLpAPR } from "./useFetchLpAPR";

const decimals = 0;

export const useFetchUscEthLpDetailedAPR = () => {
  const { apr: aprData, isFetchedAPR } = useFetchLpAPR();

  // Assuming aprData is an array of bigint or undefined if not fetched
  const result: bigint[] | undefined = aprData as unknown as
    | bigint[]
    | undefined;

  const totalAprOriginal = result ? result[3] : undefined;
  const chiAprOriginal = result ? result[2] : undefined;
  const stEthAprOriginal = result ? result[0] : undefined;
  const weEthAprOriginal = result ? result[1] : undefined;

  // Formatting original APR values
  const totalAprFormatted = formatUnits(totalAprOriginal || 0n, decimals);
  const chiAprFormatted = formatUnits(chiAprOriginal || 0n, decimals);
  const stEthAprFormatted = formatUnits(stEthAprOriginal || 0n, decimals);
  const weEthAprFormatted = formatUnits(weEthAprOriginal || 0n, decimals);

  // Converting formatted APR values to displayable percentage strings
  const formatAprForDisplay = (aprValue: string) =>
    formatMoney(Number(aprValue || 0));

  // Constructing the detailed APR
  const detailedApr = {
    totalApr: {
      original: totalAprOriginal,
      formatted: formatAprForDisplay(totalAprFormatted),
    },
    chiApr: {
      original: chiAprOriginal,
      formatted: formatAprForDisplay(chiAprFormatted),
    },
    stEthApr: {
      original: stEthAprOriginal,
      formatted: formatAprForDisplay(stEthAprFormatted),
    },
    weEthApr: {
      original: weEthAprOriginal,
      formatted: formatAprForDisplay(weEthAprFormatted),
    },
  };

  // Preparing displayable data
  const aprDisplayable = {
    totalApr: {
      value: detailedApr.totalApr.formatted,
      isFetched: isFetchedAPR,
      label: "Total APR",
    },
    chiApr: {
      value: detailedApr.chiApr.formatted,
      isFetched: isFetchedAPR,
      label: "CHI APR",
    },
    stEthApr: {
      value: detailedApr.stEthApr.formatted,
      isFetched: isFetchedAPR,
      label: "stCHI APR",
    },
    weEthApr: {
      value: detailedApr.weEthApr.formatted,
      isFetched: isFetchedAPR,
      label: "weEth APR",
    },
  };

  return {
    aprDisplayable,
    detailedApr,
    isFetchedAPR,
  };
};

import { contractAddresses, DataProviderAbi } from "@meta";
import { formatMoney } from "@utils";
import { formatUnits } from "viem";

import { useReadContract } from "wagmi";

const decimals = 0;

const TBD = true;

export const useFetchBoostedChiEthApr = () => {
  const { data: apr, isFetched: isFetchedAPR } = useReadContract({
    abi: DataProviderAbi,
    functionName: "chiEthLpLockingApr",
    address: contractAddresses.DataProvider,
    args: [
      contractAddresses.CHI,
      contractAddresses.CHI_ETH_LP_Locking,
      contractAddresses.StakingManager,
      contractAddresses.PriceFeedAggregator,
      contractAddresses.USC_ETH_LP,
      contractAddresses.CHI_ETH_LP,
    ],
  });

  const result: bigint[] | undefined = apr as unknown as bigint[] | undefined;

  const formatAprForDisplay = (aprValue: string) =>
    formatMoney(Number(aprValue || 0));

  const totalAprOriginal = result ? result[2] : undefined;
  const baseAprOriginal = result ? result[0] : undefined;
  const extraAprOriginal = result ? result[1] : undefined;

  const totalAprFormatted = formatUnits(totalAprOriginal || 0n, decimals);
  const baseAprFormatted = formatUnits(baseAprOriginal || 0n, decimals);
  const extraAprFormatted = formatUnits(extraAprOriginal || 0n, decimals);

  return {
    totalApr: {
      original: totalAprOriginal,
      formatted: TBD ? "TBD" : formatAprForDisplay(totalAprFormatted),
    },
    baseApr: {
      original: baseAprOriginal,
      formatted: TBD ? "TBD" : formatAprForDisplay(baseAprFormatted),
    },
    extraApr: {
      original: extraAprOriginal,
      formatted: TBD ? "TBD" : formatAprForDisplay(extraAprFormatted),
    },
    isFetchedAPR,
  };
};

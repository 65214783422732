import { ChiLockingAprAbi, contractAddresses } from "@meta";
import { useReadContract } from "wagmi";

export const useFetchChiLockingAPR = () => {
  const { data: apr, isFetched: isFetchedAPR } = useReadContract({
    abi: ChiLockingAprAbi,
    functionName: "chiLockingAPR",
    address: contractAddresses.CHI_LOCKING_APR,
    args: [
      contractAddresses.CHI,
      contractAddresses.ChiStaking,
      contractAddresses.ChiLocking,
      contractAddresses.USCStaking,
      contractAddresses.USC_ETH_LP_Staking,
      contractAddresses.CHI_ETH_LP_Staking,
      contractAddresses.ChiVesting,
      contractAddresses.RewardController,
      contractAddresses.PriceFeedAggregator,
      contractAddresses.ReserveHolder,
    ],
  });

  return {
    apr,
    isFetchedAPR,
  };
};

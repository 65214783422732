import { contractAddresses, USCStakingAbi } from "@meta";
import {
  useWingsContractWrite,
  Typography,
  useNotificationContext,
  Button,
  FlexCol,
  MyFormProvider,
} from "@shared";
import { useForm } from "react-hook-form";
import { parseUnits } from "viem";

export const SetEmissionPerSecond: React.FC = () => {
  const { writeContractAsync: startStaking, isPending } =
    useWingsContractWrite();

  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    await startStaking(
      {
        abi: USCStakingAbi,
        address: contractAddresses.USCStaking,
        functionName: "setEmissionPerSecond",
        args: [parseUnits(data.emissionPerSecond, 18)],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: (
              <Typography>The emission has been set successfully.</Typography>
            ),
          });
        },
      }
    );
  };

  return (
    <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography>Set emission per second</Typography>
      <FlexCol className="gap-3">
        <input
          {...methods.register("emissionPerSecond", { required: true })}
          placeholder="Emission per second"
          className="input input-bordered w-full"
        />
        <Button type="submit" loading={isPending}>
          Set emission per second
        </Button>
      </FlexCol>
    </MyFormProvider>
  );
};

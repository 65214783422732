import { useQuery } from "@tanstack/react-query";
import { ethers } from "ethers";
import { Address, erc20Abi } from "viem";
import { useAccount } from "wagmi";
import { displayTokens } from "@utils";
import { Displayable } from "@shared";
import { TOKEN_CONFIG } from "../../config/tokenConfig";

export const rpcUrls = {
  1: import.meta.env.VITE_RPC_URL, // Mainnet
  8453: import.meta.env.VITE_BASE_RPC_URL,
  42161: import.meta.env.VITE_ARBITRUM_RPC_URL,
  81457: import.meta.env.VITE_BLAST_RPC_URL,
  10: import.meta.env.VITE_OPTIMISM_RPC_URL,
  59144: import.meta.env.VITE_LINEA_RPC_URL,
  34443: import.meta.env.VITE_MODE_RPC_URL,
  137: import.meta.env.VITE_POLYGON_RPC_URL,
  324: import.meta.env.VITE_ZKSYNC_RPC_URL,
};

export const getProviderByChainId = (chainId: number) => {


  return new ethers.providers.JsonRpcProvider((rpcUrls as any)[chainId]);
};

export const useEthersBalance = (
  chainId?: number
): Displayable<{
  originalValue: bigint | undefined;
  viewValue: string;
}> => {
  const { address } = useAccount();

  const fetchBalance = async () => {
    if (!address || !chainId) return null;

    const provider = getProviderByChainId(chainId);
    const balance = await provider.getBalance(address);
    return balance;
  };

  const { data, ...rest } = useQuery({
    queryKey: ["ethBalance", address, chainId],
    queryFn: fetchBalance,
    enabled: !!address && !!chainId,
  });

  const result = {
    data: {
      originalValue: data?.toBigInt(),
      viewValue: displayTokens(data?.toBigInt(), {}),
    },
    ...rest,
  };

  return result;
};

export const useEthersErc20Balance = (
  chainId?: number,
  tokenAddress?: Address
) => {
  const { address } = useAccount();
  const tokenConfig = TOKEN_CONFIG[(tokenAddress as Address) || ""];

  const fetchTokenBalance = async () => {
    if (!address || !chainId || !tokenAddress) return null;

    const provider = getProviderByChainId(chainId);
    const contract = new ethers.Contract(tokenAddress, erc20Abi, provider);
    const balance = await contract.balanceOf(address);
    return balance;
  };

  const { data, ...rest } = useQuery({
    queryKey: ["erc20Balance", address, chainId, tokenAddress],
    queryFn: fetchTokenBalance,
    enabled: !!address && !!chainId && !!tokenAddress,
  });

  const result = {
    data: {
      originalValue: data?.toBigInt(),
      viewValue: displayTokens(data?.toBigInt() || 0, {
        decimals: tokenConfig?.decimals || 0,
      }),
    },
    ...rest,
  };

  return result;
};

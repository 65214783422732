import { useReadContract } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import { DisplayValue, Typography } from "../../../../shared";
import { formatUnits } from "viem";

export const DisplayMintBurnFee: React.FC = () => {
  const { data: mintBurnFee, ...rest } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: "mintBurnFee",
  });

  return (
    <div className="flex flex-row gap-2">
      <Typography>Mint Burn Fee:</Typography>
      <DisplayValue
        viewValue={formatUnits(BigInt(mintBurnFee || 0), 2)}
        {...rest}
      />
    </div>
  );
};

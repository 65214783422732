import { FlexCol } from "@shared";
import { VeChiStats } from "./veChiStats/VeChiStats";
import { Analytics } from "../../../../components/rightSection/Analytics";
import { YourClaimablePositions } from "../../../../components/rightSection/yourRewards/YourClaimablePositions";
import { BoostCountdown } from "../../../../components/rightSection/countdown/BoostCountdown";

export const RightSection = () => {
  return (
    <FlexCol className="w-full">
      <FlexCol className="gap-4">
        <VeChiStats />

        <YourClaimablePositions />

        <BoostCountdown
          initialStartingDatetime={new Date("2024-03-11T09:00:00+01:00")}
          durationInDays={7}
        />
        <Analytics />
      </FlexCol>
    </FlexCol>
  );
};

import { useCallback } from "react";
import { useAccount } from "wagmi";
import { getProviderByChainId } from "../erc20/useBalanceEthers"; // Ensure this returns a string, not a function

export interface ButtonPropsOverride {
  textOverride?: string;
  onClickOverride?: () => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
}

export const useButtonPropsOverride = () => {
  const { chainId } = useAccount();

  const getButtonPropsOverride = useCallback(
    (targetChainId?: number) => {
      if (!targetChainId || !chainId) return undefined;

      if (chainId === targetChainId) {
        return undefined;
      } else {
        return {
          textOverride: "Switch to correct network",
          onClick: async () => {
            if (window && window.ethereum) {
              try {
                // Attempt to switch to the target network
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: "0x" + targetChainId.toString(16) }],
                });
              } catch (error) {
                if ((error as any).code === 4902) {
                  // Chain is not added, so add it
                  try {
                    const rpcUrl = getProviderByChainId(targetChainId); // Ensure this is a valid string
                    if (typeof rpcUrl === "string") {
                      await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                          {
                            chainId: "0x" + targetChainId.toString(16),
                            chainName: "Your Custom Chain", // Customize with the chain name
                            rpcUrls: [rpcUrl], // Ensure the RPC URL is correct and points to the right URL
                            nativeCurrency: {
                              name: "Your Native Currency", // Customize with your token name
                              symbol: "XYZ", // Customize with your token symbol
                              decimals: 18,
                            },
                            blockExplorerUrls: ["https://explorer.com"], // Customize with the explorer URL
                          },
                        ],
                      });
                    } else {
                      console.error("Invalid RPC URL returned for chain ID", targetChainId);
                    }
                  } catch (addError) {
                    console.error("Error adding the chain: ", addError);
                  }
                } else {
                  console.error("Error switching chain: ", error);
                }
              }
            }
          },
          disabled: false,
          type: "button",
        };
      }
    },
    [chainId]
  );

  return { getButtonPropsOverride };
};

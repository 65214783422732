import React, { useState } from "react";
import { FlexRow, RainbowKitCustom } from "@shared";
import { ReactSVG } from "react-svg";
import ChiLogo from "@assets/logos/chi-logo.svg";
import MenuIcon from "@assets/layout/menuIcon.svg";
import XIcon from "@assets/layout/xIcon.svg";
import { ModalBody } from "@shared";
import { navItems } from "./NavSidebar";
import { MenuItem } from "./MenuItem";
import { vestingWhiteListedAddresses } from "@meta";
import { IS_DEV_MODE } from "../../../globals";
import { useAccount } from "wagmi";

export const MobileNavBar: React.FC = () => {
  const { address } = useAccount();

  const [isModalOpen, setModalOpen] = useState(false);

  const handleMenuItemClick = () => {
    setModalOpen(false);
  };

  const isVestAllowed =
    IS_DEV_MODE ||
    vestingWhiteListedAddresses
      .map((addr) => addr.toLowerCase())
      .includes(address?.toLowerCase() || "");

  return (
    <>
      {/* Navigacija */}
      <div
        className={`fixed z-50 top-0 left-0 w-full ${
          isModalOpen ? "bg-white" : "bg-[#e7edf6]"
        } transition-colors duration-300`}
      >
        <FlexRow className="justify-between items-center p-3">
          <a href="/">
            <ReactSVG src={ChiLogo} className="w-[174px] h-[40px]" />
          </a>
          <button
            onClick={() => setModalOpen((prev) => !prev)}
            aria-label={isModalOpen ? "Close Menu" : "Open Menu"}
          >
            <ReactSVG
              src={isModalOpen ? XIcon : MenuIcon} // Menja ikonicu
              width="24px"
              height="24px"
            />
          </button>
        </FlexRow>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50 md:hidden">
          <ModalBody setModalOpen={setModalOpen} size="mobile">
            <div className="flex flex-col gap-4">
              <nav className="flex flex-col gap-1">
                {navItems.map((item) => {
                  if (item.label === "Vest" && !isVestAllowed) return null;
                  return (
                    <MenuItem
                      key={item.route}
                      item={item}
                      onClick={handleMenuItemClick}
                    />
                  );
                })}
              </nav>
              <RainbowKitCustom />
            </div>
          </ModalBody>
        </div>
      )}
    </>
  );
};

import {
  Card,
  Divider,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";
import { YourPositionsCard } from "./cards/YourPositionsCard";
import { YourRewardsCard } from "./cards/YourRewardsCard";
import { InfoSection } from "./InfoSection";
import { AuditedBy } from "../../../components/auditedBy/AuditedBy";

//ICONS
import RewardsIcon from "@assets/layout/bank-note.svg";
import { BoostingTableBody } from "./boostingTable/BoostingTableBody";
// import { TableBody } from "./table/TableBody";

export const Main = () => {

  return (
    <FlexCol className="gap-6">
      <Card hasBorder className="overflow-hidden">
        <FlexCol className="w-full">
          <FlexCol className="gap-6">
            <FlexCol className="px-4 pb-4 sm:px-8 pt-8 gap-6 bg-white">
              <FlexCol className="gap-4">
                <FlexRow className="items-center gap-4">
                  <Typography
                    type="h2"
                    className="text-button-selection-stroke"
                  >
                    Rewards
                  </Typography>
                  <SVGWrapper
                    src={RewardsIcon}
                    width={32}
                    height={32}
                    color="#0051A6"
                  />
                  <div className="hidden 2xl:block">
                    <AuditedBy />
                  </div>
                </FlexRow>
                <div className="block 2xl:hidden w-[156px]">
                  <AuditedBy />
                </div>
                <Typography
                  type="body-medium-regular"
                  className="text-neutral-black-60"
                >
                  Track your rewards across the Chi Protocol's ecosystem
                </Typography>
                <div className="flex flex-col 2xl:flex-row w-full gap-2">
                  <div className="flex w-full 2xl:w-1/2">
                    <YourRewardsCard />
                  </div>
                  <div className="flex w-full 2xl:w-1/2">
                    <YourPositionsCard />
                  </div>
                </div>
              </FlexCol>
              <Divider />
              {/* <div className="min-h-[200px]">
                <TableBody />
              </div> */}
              <div className="min-h-[200px]">
                <BoostingTableBody />
              </div>
            </FlexCol>
          </FlexCol>
        </FlexCol>
      </Card>
      <InfoSection />
    </FlexCol>
  );
};

import { Navigate } from "react-router-dom";
import { IS_DEV_MODE } from "../../../globals";
import { useAccount } from "wagmi";

interface AccessControlProps {
  allowedAddresses: string[];
  children: React.ReactNode;
}

export const AccessControl: React.FC<AccessControlProps> = ({
  allowedAddresses,
  children,
}) => {
  const { address } = useAccount();

  if (!address) {
    return <div>Loading...</div>;
  }

  const isAllowed = allowedAddresses
    .map((addr) => addr.toLowerCase())
    .includes(address.toLowerCase());

  if (!isAllowed && !IS_DEV_MODE) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
};

import { useState, useEffect } from "react";
import { parseUnits, etherUnits, Address } from "viem";
import { useAccount, useReadContract } from "wagmi";
import { contractAddresses, WstUSCAbi } from "@meta";
import {
  useWingsContractWrite,
  useNotificationContext,
  StandardNotifBody,
  TransactionComplete,
  Transfer,
  TransferMoney,
  DisplayErrorNotif,
  StepsContainer,
  ApproveComplete,
  FlexCol,
  InputField,
  FlexRow,
  Button,
  DisplayableInputField,
  SVGWrapper,
} from "@shared";
import { formatMoney, displayTokens, formatUnitsToMaxValue } from "@utils";
import { useButtonPropsOverride } from "../../../../../../state/across/buttonPropsOverride";
import { useStepState } from "../../../../../../state/common/useStepState.ui";
import { useGetWalletBalanceByToken } from "../../../../../../state/erc20/useGetWalletBalanceByToken";
import { useSpotPrice } from "../../../../../../state/usc/useSpotPrice";

/* ----------- */
/*    Icons    */
/* ----------- */
import StUscLogo from "@assets/tokens/stUsc.svg";
import WstUSCLogo from "@assets/tokens/wstUsc.svg";
import { useFetchWstUscPrice } from "../../../../../../state/wstUsc/useFetchWstUscPrice";
import {
  useFetchConvertedWstUsc,
  useFetchConvertedStUsc,
} from "../../wrap-tab/form/WrapForm";
import { useWingsApprove } from "../../../../../../state/common/useWingsApprove";

export const UnwrapForm = () => {
  const { isConnected, address } = useAccount();
  const uscPrice = useSpotPrice(contractAddresses.USC);

  const { writeContractAsync: unwrapAsync, isPending: isUnwrapping } =
    useWingsContractWrite();

  const [wstUscValue, setWstUscValue] = useState("");
  const [stUscValue, setStUscValue] = useState("");
  const [lastUpdatedField, setLastUpdatedField] = useState<
    null | "stUsc" | "wstUsc"
  >(null);
  const { approveAsync, isApproving, isApproved } = useWingsApprove(
    contractAddresses.USCStaking,
    contractAddresses.WstUSC,
    parseUnits(String(stUscValue), etherUnits.wei)
  );

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Wrap"],
    isApproved ? 1 : 0
  );
  useEffect(() => {
    if (isApproved) {
      setCurrentStep(1);
    } else setCurrentStep(0);
  }, [isApproved]);

  const { convertedWstUscValue, isWstUscFetching } =
    useFetchConvertedWstUsc(stUscValue);

  const { convertedStUscValue, isStUscFetching } =
    useFetchConvertedStUsc(wstUscValue);

  useEffect(() => {
    if (lastUpdatedField === "stUsc") {
      setWstUscValue(convertedWstUscValue);
    }
  }, [convertedWstUscValue, lastUpdatedField]);

  useEffect(() => {
    if (lastUpdatedField === "wstUsc") {
      setStUscValue(convertedStUscValue);
    }
  }, [convertedStUscValue, lastUpdatedField]);

  const onStUscChange = (value: string) => {
    setStUscValue(value);
    setLastUpdatedField("stUsc");
  };

  const onWstUscValueChange = (value: string) => {
    setWstUscValue(value);
    setLastUpdatedField("wstUsc");
  };

  const unwrapFunction = async () => {
    await unwrapAsync(
      {
        abi: WstUSCAbi,
        address: contractAddresses.WstUSC,
        functionName: "withdraw",
        args: [
          parseUnits(String(stUscValue), etherUnits.wei),
          address!,
          address!,
        ],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <Transfer
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={WstUSCLogo} width={22} height={22} />
                        }
                        text={formatMoney(wstUscValue)}
                        symbol="wstUSC"
                      />
                    }
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={StUscLogo} width={22} height={22} />
                        }
                        text={formatMoney(stUscValue)}
                        symbol="stUSC"
                      />
                    }
                  />
                }
              />
            ),
          });
          setWstUscValue("");
          setStUscValue("");
        },
      }
    );
  };

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={
            currentStep === 0 ? "Approve in Process" : "Transaction in Process"
          }
          loading
          transferComponent={
            <Transfer
              leftComponent={
                <TransferMoney
                  icon={<SVGWrapper src={WstUSCLogo} width={22} height={22} />}
                  text={formatMoney(wstUscValue)}
                  symbol="wstUSC"
                />
              }
              rightComponent={
                <TransferMoney
                  icon={<SVGWrapper src={StUscLogo} width={22} height={22} />}
                  text={formatMoney(stUscValue)}
                  symbol="stUSC"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleButtonClick = async () => {
    loadingNotif(currentStep);
    if (!isApproved) {
      await approveAsync(parseUnits(String(stUscValue), etherUnits.wei), {
        onSuccess: async (txHash?: string) => {
          showNotification({
            txHash,
            status: "success",
            content: (
              <StandardNotifBody
                headerComponent={<ApproveComplete />}
                transferComponent={
                  <Transfer
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={WstUSCLogo} width={22} height={22} />
                        }
                        text={formatMoney(wstUscValue)}
                        symbol="wstUSC"
                      />
                    }
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={StUscLogo} width={22} height={22} />
                        }
                        text={formatMoney(stUscValue)}
                        symbol="stUSC"
                      />
                    }
                  />
                }
                stepsComponent={
                  <StepsContainer
                    stepNames={steps}
                    currentStep={currentStep}
                    loading
                  />
                }
              />
            ),
          });
          setCurrentStep(1);
          loadingNotif(1);
          await unwrapFunction();
        },
        onError: (message?: string) => {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={message} />,
          });
        },
      });
    } else {
      await unwrapFunction();
    }
  };

  const { data: wstUscBalance, isFetching } = useReadContract({
    abi: WstUSCAbi,
    address: contractAddresses.WstUSC,
    functionName: "balanceOf",
    args: [address!],
  });

  const wstUscBalanceDSPL: DisplayableInputField = {
    value: displayTokens(wstUscBalance, {}),
    isFetched: !isFetching,
    label: "",
  };
  const { isLoading: isStUscBalanceLoading, balance: stUscBalance } =
    useGetWalletBalanceByToken(contractAddresses.USCStaking);

  const displayableStUscWalletBalance: DisplayableInputField = {
    value: displayTokens(stUscBalance, {}),
    isFetched: !isStUscBalanceLoading,
    label: "",
  };

  const { formattedPrice: wstUscPrice } = useFetchWstUscPrice();

  const displayableWstUscDollarValue: DisplayableInputField = {
    value: formatMoney(Number(wstUscValue) * wstUscPrice),
    label: "Value: $ ",
  };
  const displayableStUscDollarValue: DisplayableInputField = {
    value: formatMoney(Number(stUscValue) * Number(uscPrice?.spotPrice)),
    label: "Value: $ ",
  };

  const { getButtonPropsOverride } = useButtonPropsOverride();

  return (
    <FlexCol className="gap-1">
      <div className="md:mt-[18.74px] mt-[12.98px]">
        <InputField
          name="WstUSCAmount"
          value={wstUscValue}
          onChange={(e) => onWstUscValueChange(e.target.value)}
          label="Unwrap wstUSC"
          placeholder={"Amount"}
          leftLabel={
            <FlexRow className="items-center gap-2">
              <SVGWrapper src={WstUSCLogo} width={24} height={24} />
            </FlexRow>
          }
          rightLabel={
            <button
              className="bg-primary-10 text-primary rounded-md w-16 h-9 px-4 py-2 flex items-center justify-center text-body-small-medium"
              disabled={!stUscBalance || !isConnected}
              onClick={() => {
                onWstUscValueChange(
                  formatUnitsToMaxValue(wstUscBalance)?.toString()
                );
              }}
            >
              MAX
            </button>
          }
          walletBalance={wstUscBalanceDSPL}
          dollarValue={displayableWstUscDollarValue}
        />
      </div>
      <div className="md:mt-[18.74px] mt-[8.98px]">
        <InputField
          name="StUSCAmount"
          value={stUscValue}
          onChange={(e) => onStUscChange(e.target.value)}
          label="To Receive"
          placeholder={"Amount"}
          leftLabel={
            <FlexRow className="items-center gap-2">
              <SVGWrapper src={StUscLogo} width={24} height={24} />
            </FlexRow>
          }
          walletBalance={displayableStUscWalletBalance}
          dollarValue={displayableStUscDollarValue}
        />
      </div>

      <Button
        className="mt-[8.98px] md:mt-[19px]"
        onClick={handleButtonClick}
        disabled={
          !isConnected ||
          (!wstUscValue && !stUscValue) ||
          isWstUscFetching ||
          isStUscFetching
        }
        loading={isUnwrapping || isApproving}
        fullWidth
        size="big"
        color="primary"
        {...getButtonPropsOverride(1)}
      >
        {Number(wstUscValue || 0) > 0 || Number(stUscValue || 0) > 0
          ? isApproved
            ? "Unwrap wstUSC"
            : "Approve"
          : "Enter amount"}
      </Button>
    </FlexCol>
  );
};

import { mainnet } from "wagmi/chains";
import { Config, createConfig, fallback, http, webSocket } from "wagmi";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet,
  rabbyWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

const rpcConfig = [
  { url: import.meta.env.VITE_RPC_URL, isWebSocket: false },
  { url: import.meta.env.VITE_RPC_URL2, isWebSocket: false },
].filter(({ url }) => url);

const connectors = connectorsForWallets(
  [
    {
      groupName: "Popular",
      wallets: [
        metaMaskWallet,
        rabbyWallet,
        walletConnectWallet,
        rainbowWallet,
      ],
    },
  ],
  {
    appName: "Chi Protocol | USC The Stablecoin Backed By LSTs",
    appDescription:
      "USC is a decentralised, scalable, and fully backed stablecoin that is 1-1 USD pegged.",
    appUrl: "https://chiprotocol.io/",
    // appIcon: todo chi logo,
    projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID || "",
  }
);

export const config = createConfig({
  connectors,
  chains: [mainnet],
  transports: {
    [mainnet.id]: fallback(
      rpcConfig.map(({ url, isWebSocket }) =>
        isWebSocket ? webSocket(url) : http(url)
      ),
      { rank: true }
    ),
  },
});

declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}

export const getWagmiConfig = (): Config => {
  return config;
};

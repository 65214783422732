import { StakingManagerAbi, contractAddresses } from "@meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  MyFormProvider,
  FlexCol,
  RHFDateTime,
  Button,
  FlexRow,
} from "@shared";
import { useForm } from "react-hook-form";
import { parseUnits } from "viem";

export const ConfigureRewardTokenForm: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: configureRewardToken, isPending } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await configureRewardToken(
      {
        abi: StakingManagerAbi,
        address: contractAddresses.StakingManager,
        functionName: "configureRewardToken",
        args: [
          data.stakingToken,
          data.rewardToken,
          {
            startTimestamp: BigInt(
              new Date(data.startTimestamp).getTime() / 1000
            ),
            endTimestamp: BigInt(new Date(data.endTimestamp).getTime() / 1000),
            emissionPerSecond: parseUnits(data.emissionPerSecond, 18), //todo add decimals config
          },
        ],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: (
              <Typography>Reward token configured successfully</Typography>
            ),
          });
        },
      }
    );
  };

  return (
    <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography>Configure Reward Token</Typography>
      <FlexCol className="gap-3">
        <input
          {...methods.register("stakingToken", { required: true })}
          placeholder="Staking Token Address"
          className="input input-bordered w-full"
        />
        <input
          {...methods.register("rewardToken", { required: true })}
          placeholder="Reward Token Address"
          className="input input-bordered w-full"
        />
        <FlexRow className="gap-3">
          <Typography>Start Timestamp</Typography>
          <RHFDateTime
            name="startTimestamp"
            rules={{ required: "Start timestamp is required" }}
          />
        </FlexRow>

        <FlexRow className="gap-3">
          <Typography>End Timestamp</Typography>
          <RHFDateTime
            name="endTimestamp"
            rules={{ required: "End timestamp is required" }}
          />
        </FlexRow>
        <input
          {...methods.register("emissionPerSecond", { required: true })}
          placeholder="Emission Per Second (e.g., 1)"
          className="input input-bordered w-full"
          type="number"
          step="any"
        />
        <Button type="submit" loading={isPending}>
          Configure Reward Token
        </Button>
      </FlexCol>
    </MyFormProvider>
  );
};

export const ChiLockingAprAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "chi",
        type: "address",
      },
      {
        internalType: "contract ChiStaking",
        name: "chiStaking",
        type: "address",
      },
      {
        internalType: "contract ChiLocking",
        name: "chiLocking",
        type: "address",
      },
      {
        internalType: "contract USCStaking",
        name: "uscStaking",
        type: "address",
      },
      {
        internalType: "contract LPStaking",
        name: "uscEthLpStaking",
        type: "address",
      },
      {
        internalType: "contract LPStaking",
        name: "chiEthLpStaking",
        type: "address",
      },
      {
        internalType: "contract ChiVesting",
        name: "chiVesting",
        type: "address",
      },
      {
        internalType: "contract RewardControllerV2",
        name: "rewardController",
        type: "address",
      },
      {
        internalType: "contract IPriceFeedAggregator",
        name: "priceFeedAggregator",
        type: "address",
      },
      {
        internalType: "contract ReserveHolder",
        name: "reserveHolder",
        type: "address",
      },
    ],
    name: "chiLockingAPR",
    outputs: [
      {
        internalType: "uint256",
        name: "totalApr",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "chiApr",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "stChiApr",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

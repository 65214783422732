import {
  Card,
  FlexCol,
  FlexRow,
  SVGWrapper,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";

import { FormTabButton } from "../../../../components/tab/FormTabButton";
import { FormContainer } from "./mint-tab/form/FormContainer";
import { ChainIdProvider } from "../../contexts/ChainIdContext";
import { UscMarketCapBanner } from "../../../../components/uscMarketCapBanner/UscMarketCapBanner";
import { MintDepositPanel } from "./mint-tab/MintDepositPanel";
import { RedeemDepositPanel } from "./redeem-tab/RedeemDepositPanel";
import { RedeemForm } from "./redeem-tab/form/RedeemForm";
import { useActiveTabsFromLocation } from "../../../../state/activeTab/useTabsFromLocation";
import { AuditedBy } from "../../../../components/auditedBy/AuditedBy";

import MintIcon from "@assets/layout/stars.svg";
import { useState } from "react";
import { Address } from "viem";
import { contractAddresses } from "../../../../../meta";
import { useAccount } from "wagmi";

export const Main = () => {
  const { isConnected } = useAccount();

  const [isMintAndStake, setIsMintAndStake] = useState<boolean>(false);
  const { activeMintFormTab } = useActiveTabsFromLocation();

  /* ------------- */
  /*   Asset state */
  /* ------------- */
  const [selectedAsset, setSelectedAsset] = useState<Address | undefined>(
    contractAddresses.ETH
  );


  const [selectedRedeemAsset, setSelectedRedeemAsset] = useState<Address>(
    contractAddresses.WETH
  );

  return (
    <Card hasBorder className=" overflow-hidden">
      <FlexCol className="w-full">
        <FlexCol className="gap-6">
          <FlexCol className="px-4 sm:px-8 pt-8 gap-6 bg-white">
            <FlexRow className="items-center gap-4">
              <Typography type="h2" className="text-button-selection-stroke">
                {activeMintFormTab} USC
              </Typography>
              <SVGWrapper
                src={MintIcon}
                width={32}
                height={32}
                color="#0051A6"
              />
              <div className="hidden xs:block">
                <AuditedBy />
              </div>
            </FlexRow>
            <div className="block xs:hidden w-[156px]">
              <AuditedBy />
            </div>
            <UscMarketCapBanner />
          </FlexCol>
          {/* Tab Section */}
          <FlexCol className="w-full   bg-neutral-black-10">
            <TabProvider defaultTab={"Mint"} overrideUrlSlug="formTab">
              {/* Tab Buttons */}
              <FlexRow className="bg-white w-full px-8">
                <FormTabButton tab="Mint" className="flex-1 py-2">
                  Mint
                </FormTabButton>
                <FormTabButton tab="Redeem" className="flex-1 py-2">
                  Redeem
                </FormTabButton>
              </FlexRow>

              {/* Mint Section */}
              <TabContent tab="Mint">
                <ChainIdProvider>
                  {isConnected && (
                    <MintDepositPanel
                      isMintAndStake={isMintAndStake}
                      setIsMintAndStake={setIsMintAndStake}
                      selectedAsset={selectedAsset}
                    />
                  )}
                  <FlexCol className="p-2 pt-4 gap-4 sm:p-6">
                    <FormContainer
                      selectedAsset={selectedAsset}
                      setSelectedAsset={setSelectedAsset}
                      isMintAndStake={isMintAndStake}
                    />
                  </FlexCol>
                </ChainIdProvider>
              </TabContent>

              {/* Redeem Section */}
              <TabContent tab="Redeem">
                <ChainIdProvider>
                  {isConnected && <RedeemDepositPanel selectedAsset={selectedRedeemAsset} />}
                  <FlexCol className="px-2 pb-6  gap-4 sm:px-6">
                    <RedeemForm selectedAsset={selectedRedeemAsset} setSelectedAsset={setSelectedRedeemAsset} />
                  </FlexCol>
                </ChainIdProvider>
              </TabContent>
            </TabProvider>
          </FlexCol>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};

import {
  Card,
  DisplayNumber,
  FlexCol,
  FlexRow,
  SingleComponent,
  StandardNotifBody,
  StepsContainer,
  SVGWrapper,
  TransactionComplete,
  TransferMoney,
  Typography,
  useNotificationContext,
  useWingsContractWrite,
} from "@shared";
import { InfoTooltip } from "../../tooltip/InfoTooltip";

/* ----------- */
/*    Icons    */
/* ----------- */
import Chi from "@assets/tokens/chi.svg";
import { useCHIStakes } from "../../../state/chi/useCHIStakes";
import { CHIStakingAbi, contractAddresses } from "../../../../meta";
import { useAccount } from "wagmi";
import { useStepState } from "../../../state/common/useStepState.ui";

import chiIcon from "@assets/tokens/chi.svg";
import { Address } from "viem";

export const YourClaimablePositions = () => {
  const { address } = useAccount();
  const { stake, viewValue, isFetched } = useCHIStakes(address);

  const { writeContractAsync: unstakeAsync, isPending: isUnstaking } =
    useWingsContractWrite();

  const { showNotification } = useNotificationContext();

  const { steps } = useStepState(["Claim"], 1);

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={chiIcon} width={22} height={22} />}
                  text={viewValue}
                  symbol="CHI"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const onUnstake = async () => {
    loadingNotif(1);
    await unstakeAsync(
      {
        abi: CHIStakingAbi,
        address: contractAddresses.ChiStaking,
        functionName: "unstake",
        args: [stake],
      },
      {
        onSuccess: (txHash: Address) => {
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={chiIcon} width={22} height={22} />
                        }
                        text={viewValue}
                        symbol="CHI"
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };

  return (
    <Card hasBorder className="p-4 ">
      <FlexCol className="gap-5">
        <Typography type="body-medium-bold">
          Your Claimable Positions
        </Typography>
        <FlexCol className="gap-3">
          <FlexRow className="justify-between">
            <FlexRow className="gap-2 items-center">
              <SVGWrapper src={Chi} width={20} height={20} />
              <Typography type="body-small-regular">CHI</Typography>
              <InfoTooltip>
                {/* Tooltip content displaying detailed stETH information */}
                <FlexCol className="w-[200px] h-[116px] p-3 gap-2 rounded-2xl">
                  <Typography type="body-small-medium">CHI</Typography>
                  <Typography
                    type="caption-regular"
                    className="text-neutral-black-60"
                  >
                    CHI rewards from veCHI positions are automatically locked
                    for the same duration as the underlying lock.
                  </Typography>
                </FlexCol>
              </InfoTooltip>
              <button
                onClick={async () => {
                  await onUnstake();
                }}
                disabled={isUnstaking || !isFetched || stake == 0n}
                className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md disabled:opacity-30"
              >
                Claim
              </button>
            </FlexRow>
            <DisplayNumber
              viewValue={viewValue}
              isLoading={!isFetched}
              typography="body-small-medium"
            />
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};

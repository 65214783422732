import {
  FlexCol,
  FlexRow,
  Typography,
  DisplayTokenAmount,
  DisplayMoney,
  SVGWrapper,
} from "@shared";

//icons
import Plus from "@assets/layout/plus-circle.svg";
import { useWatchAsset } from "wagmi";
import { WalletBadge } from "../../../../../components/walletBadge/WalletBadge";
import { contractAddresses } from "@meta";
import { useGetWalletBalanceByToken } from "../../../../../state/erc20/useGetWalletBalanceByToken";
import { displayTokens } from "@utils";
import { useGetPriceByAddress } from "../../../../../state/price-feed-aggregator/useGetPriceByAddress";
import { Address } from "viem";
import { TOKEN_CONFIG } from "../../../../../config/tokenConfig";

export const RedeemDepositPanel: React.FC<{
  selectedAsset: Address;
}> = ({ selectedAsset }) => {
  /* ------------- */
  /*    Methods    */
  /* ------------- */
  const { watchAsset } = useWatchAsset();

  /* ----------- */
  /*    Fetch    */
  /* ----------- */
  const price = useGetPriceByAddress(selectedAsset);
  const balance = useGetWalletBalanceByToken(selectedAsset);
  const uscBalance = useGetWalletBalanceByToken(contractAddresses.USC);

  const handleAddWeth = () => {
    if (!selectedAsset) return;
    watchAsset({
      type: "ERC20",
      options: {
        address: selectedAsset,
        symbol: TOKEN_CONFIG[selectedAsset]?.symbol,
        decimals: TOKEN_CONFIG[selectedAsset]?.decimals,
      },
    });
  };

  return (
    <div>
      <FlexCol className="w-full gap-5 px-3 py-5 sm:px-6 sm:py-10  bg-primary selection:bg-white selection:text-primary ">
        <FlexRow className="w-full items-center justify-between">
          <FlexCol className="gap-2">
            <Typography
              type="body-small-medium"
              className="text-neutral-white-80"
            >
              Available to Deposit
            </Typography>
            <DisplayTokenAmount
              {...uscBalance}
              symbol="USC"
              symbolPosition="after"
              typography="body-large-bold"
              className="text-white"
            />
          </FlexCol>
          <FlexCol className="gap-3 items-end">
            <WalletBadge />
          </FlexCol>
        </FlexRow>
        <FlexRow className="w-full border-[1px] border-neutral-white-20"></FlexRow>
        <FlexRow className="w-full items-center justify-between">
          <FlexCol className="gap-2">
            <Typography
              type="body-small-medium"
              className="text-neutral-white-80"
            >
              ETH Balance
            </Typography>
            <FlexRow className="gap-2 items-center">
              <DisplayTokenAmount
                symbol={selectedAsset ? TOKEN_CONFIG[selectedAsset]?.symbol : ""}
                isLoading={balance.isLoading}
                viewValue={balance.viewValue}
                symbolPosition="after"
                typography="body-large-bold"
                className="text-white"
              />
              <button onClick={handleAddWeth} className="focus:outline-none">
                <SVGWrapper src={Plus} width={16} height={16} />
              </button>
            </FlexRow>
            <DisplayMoney
              symbol="$"
              viewValue={displayTokens(balance?.balance || 0n, {
                formattedPrice: price.formattedPrice,
                displayInDollars: true,
                hideTokenAmount: true,
              })}
              isLoading={!price.isFetched}
              symbolPosition="before"
              typography="caption-regular"
              className="text-neutral-white-80"
            />
          </FlexCol>
          <FlexRow className="gap-2 items-center">
            <Typography
              type="body-small-medium"
              className="text-neutral-white-56"
            >
              Instant Redemption
            </Typography>
          </FlexRow>
        </FlexRow>
      </FlexCol>
    </div>
  );
};

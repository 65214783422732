import { RowContainer } from "./RowContainer";
import { FirstElementstUSC } from "./StUSCRow";
import { TableHeader } from "./TableHeader";
import { FirstElementveCHI } from "./VeCHIRow";
import { FirstElementUSCETH } from "./USCETHRow";
import { FirstElementCHIETH, GenericRow } from "./CHIETHRow";
import { useGetPriceByAddress } from "../../../../state/price-feed-aggregator/useGetPriceByAddress";
import { CHIStakingAbi, contractAddresses, WstUSCAbi } from "@meta";
import { formatMoney, displayTokens } from "@utils";
import { useState } from "react";
import { useTotalUscStaked } from "../../../../state/usc/useTotalUscStaked";
import { useReadContract } from "wagmi";
import { useFetchChiStakingAPR } from "../../../../state/data-provider/useFetchChiStakingAPR";
import { useFetchLockedChiDetailedAprDisplayable } from "../../../../state/data-provider/useFetchLockedChiDetailedApr";
import { useFetchPercentageCircChiLocked } from "../../../../state/boost/useFetchPercentageCircChiLocked";
import { useUscEthLpTotalSupply } from "../../../../state/chiStaking/useUscEthLpTotalSupply";

import { useChiEthLpTotalSupply } from "../../../../state/chiStaking/useChiEthLpTotalSupply";

import { FirstElementwstUSC } from "./WstUSCRow";
import {
  DisplayPercentage,
  FlexCol,
  FlexRow,
  SVGWrapper,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";
import { FirstElementBoostedChiEth } from "./BoostedChiEth";
import { FirstElementBoostedUscEth } from "./BoostedUscEth";
import { InfoTooltip } from "../../../../components/tooltip/InfoTooltip";
import { useFetchUscEthLpDetailedAPR } from "../../../../state/data-provider/useFetchUscEthLpDetailedAPR";
import { useFetchChiEthLpDetailedAPR } from "../../../../state/data-provider/useFetchChiEthLpDetailedAPR";
import { useFetchBoostedUscEthApr } from "../../../../state/data-provider/useFetchBoostedUscEthApr";
import { useFetchBoostedChiEthApr } from "../../../../state/data-provider/useFetchBoostedChiEthApr";
import { FirstElementBoostedWstUSC } from "./BoostedWstUSC";
import { useFetchBoostedUscEthTVL } from "../../../../state/boost/useFetchBoostedUscEthTVL";
import { useFetchBoostedChiEthTVL } from "../../../../state/boost/useFetchBoostedChiEthTVL";
import { useFetchBoostedWstUscTVL } from "../../../../state/boost/useFetchBoostedWstUscTVL";
import { useFetchBoostedWstUscApr } from "../../../../state/data-provider/useFetchBoostedWstUscApr";

/* ----------- */
/*    Icons    */
/* ----------- */
import ChiEthReward from "@assets/tokens/chiEthReward.svg";
import Usc from "@assets/tokens/usc.svg";
import StEth from "@assets/tokens/stEth.svg";
import StUsc from "@assets/tokens/stUsc.svg";
import ChiIcon from "@assets/tokens/chi.svg";
import WeEth from "@assets/tokens/weEth.svg";
import WstUsc from "@assets/tokens/wstUsc.svg";
import RewardTokens from "@assets/tokens/tableHeaderReward.svg";
import UscEth from "@assets/tokens/uscEth.svg";
import ChiEth from "@assets/tokens/chiEth.svg";
import BoostedWstUsc from "@assets/tokens/boostedWstUsc.svg";
import BoostedChiEth from "@assets/tokens/boostedChiEth.svg";
import BoostedUscEth from "@assets/tokens/boostedUscEth.svg";
import VeChi from "@assets/tokens/veChiRounded.svg";

import { useFetchWstUscPrice } from "../../../../state/wstUsc/useFetchWstUscPrice";
import { useFetchUscStakingAPR } from "../../../../state/usc/useFetchUscStakingAPR";
import { MobileRowContainer } from "./MobileRowContainer";
import { LiquidityTabButton } from "../../../../components/tab/LiquidityTabButton";

const sumStringValues = (...values: string[]) => {
  const sum = values.reduce((acc, value) => {
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    return acc + (isNaN(parsedValue) ? 0 : parsedValue);
  }, 0);

  return sum.toString();
};
const formatAprRange = (...aprs: any[]) => {
  // Convert strings with commas to numbers
  const cleanedAprs = aprs
    .map((apr) =>
      typeof apr === "string" ? parseFloat(apr.replace(/,/g, "")) : apr
    )
    .filter((apr) => !isNaN(apr)); // Filter out invalid numbers

  if (aprs.length > 0) {
    const minApr = Math.min(...cleanedAprs);
    const maxApr = Math.max(...cleanedAprs);

    return `${minApr.toFixed(2)}% - ${maxApr.toFixed(2)}%`;
  }
  return "N/A";
};

export const DataContainer = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  // st usc //
  const { isFetched: uscStakedFetched, valueDSPL: uscTVL } =
    useTotalUscStaked();
  const { aprDSPL: stUscApr, isFetchedAPR } = useFetchUscStakingAPR();

  // st chi //
  const { formattedPrice } = useGetPriceByAddress(contractAddresses.CHI);

  const { data: totalSupplyChi, isFetched: chiSupplyF } = useReadContract({
    abi: CHIStakingAbi,
    functionName: "totalSupply",
    address: contractAddresses.ChiStaking,
  });
  const chiTVLDSPL = displayTokens(totalSupplyChi, {
    formattedPrice,
    hideTokenAmount: true,
    displayInDollars: true,
  });

  const { aprDSPL, isFetchedAPR: aprChiStakingIsFetched } =
    useFetchChiStakingAPR();

  //---vechi----//
  const aprData = useFetchLockedChiDetailedAprDisplayable();
  const { lockedCHI } = useFetchPercentageCircChiLocked();
  const {
    chiApr,
    totalApr,
    isFetchedAPR: isVeChiFetched,
  } = useFetchLockedChiDetailedAprDisplayable();

  const veChiTvlDSPL = displayTokens(lockedCHI.value, {
    formattedPrice,
    displayInDollars: true,
    hideTokenAmount: true,
  });

  // ---- USClp --- //
  const { valueDSPL: ethlpDSPL, isFetched: isFethlp } =
    useUscEthLpTotalSupply();
  const { detailedApr: uscEthApr, isFetchedAPR: isFetchedETHLPAPR } =
    useFetchUscEthLpDetailedAPR();

  //---chilp--- //
  const { valueDSPL: chiEthTVL, isFetched: isFChiEthTvl } =
    useChiEthLpTotalSupply();
  const { detailedApr: chiEthApr, isFetchedAPR: isChiEthFetched } =
    useFetchChiEthLpDetailedAPR();

  //---wstUSC---//
  const { data: wstUscTVL, isFetched: isWstUscTvlFetched } = useReadContract({
    abi: WstUSCAbi,
    address: contractAddresses.WstUSC,
    functionName: "totalSupply",
  });

  const { formattedPrice: wstUscPrice, isFetched: isWstUscPriceFetched } =
    useFetchWstUscPrice();

  const wstUscTVLDSPL = displayTokens(wstUscTVL, {
    formattedPrice: wstUscPrice,
    hideTokenAmount: true,
    displayInDollars: true,
  });

  //--- LP Boosted ---//
  const {
    totalApr: totalBoostedUscEthApr,
    extraApr: boostedUscEthExtraApr,
    isFetchedAPR: isBoostedUscEthAprFetched,
  } = useFetchBoostedUscEthApr();
  const {
    totalApr: totalBoostedChiEthApr,
    extraApr: boostedChiEthExtraApr,
    isFetchedAPR: isBoostedChiEthAprFetched,
  } = useFetchBoostedChiEthApr();
  const {
    totalApr: totalBoostedWstUscApr,
    extraApr: boostedWstUscExtraApr,
    isFetchedAPR: isBoostedWstUscAprFetched,
  } = useFetchBoostedWstUscApr();

  console.log(boostedChiEthExtraApr);
  console.log(boostedUscEthExtraApr);
  console.log(boostedWstUscExtraApr);

  const { viewValue: boostedUscEthTVL, isFetched: isBoostedUscEthTVLFetched } =
    useFetchBoostedUscEthTVL();

  const { viewValue: boostedChiEthTVL, isFetched: isBoostedChiEthTVLFetched } =
    useFetchBoostedChiEthTVL();

  const { viewValue: boostedWstUscTVL, isFetched: isBoostedWstUscTVLFetched } =
    useFetchBoostedWstUscTVL();

  const aprRange = formatAprRange(
    stUscApr.viewValue,
    aprDSPL.value,
    aprData.totalApr,
    uscEthApr.totalApr.formatted,
    chiEthApr.totalApr.formatted,
    totalBoostedUscEthApr.formatted,
    totalBoostedChiEthApr.formatted,
    totalBoostedWstUscApr.formatted
  );

  const sum = sumStringValues(
    uscTVL,
    chiTVLDSPL,
    veChiTvlDSPL,
    ethlpDSPL,
    chiEthTVL,
    boostedChiEthTVL,
    boostedUscEthTVL
  );

  return (
    <>
      {/* DESKTOP  */}
      <div
        className={`hidden md:block bg-white border border-[#EEE]  shadow-[0px_2px_2px_0px_rgba(0,0,0,0.02)] rounded-2xl border-solid`}
      >
        <TableHeader
          apr={aprRange}
          tvl={"$" + formatMoney(sum)}
          toggleCollapse={toggleCollapse}
          isCollapsed={isCollapsed}
          isFetched={
            isFChiEthTvl &&
            isFethlp &&
            isWstUscTvlFetched &&
            isWstUscPriceFetched &&
            uscStakedFetched &&
            chiSupplyF
          }
        />
        {!isCollapsed && (
          <RowContainer>
            <FlexRow className="w-full  border-b bg-neutral-black-10 px-3 py-[6px]">
              <Typography
                type="body-small-regular"
                className="text-neutral-black-60"
              >
                Staking
              </Typography>
            </FlexRow>
            <GenericRow
              firstElement={<FirstElementstUSC />}
              tvlDSPL={uscTVL}
              isFetched={uscStakedFetched}
              totalApr={stUscApr.viewValue}
              rewardIcon={Usc}
              isActive
              isFetchedAPR={isFetchedAPR}
              aprTooltip={
                <APRTooltip
                  items={[
                    {
                      icon: StUsc,
                      name: "stUSC",
                      apr: stUscApr.viewValue || "N/A",
                    },
                  ]}
                />
              }
            />

            <GenericRow
              firstElement={<FirstElementwstUSC />}
              tvlDSPL={wstUscTVLDSPL}
              isFetched={isWstUscPriceFetched && isWstUscTvlFetched}
              totalApr={stUscApr.viewValue}
              rewardIcon={Usc}
              isActive
              isFetchedAPR={isFetchedAPR}
              aprTooltip={
                <APRTooltip
                  items={[
                    {
                      icon: Usc,
                      name: "USC",
                      apr: stUscApr.viewValue || "N/A",
                    },
                  ]}
                />
              }
            />

            <GenericRow
              firstElement={<FirstElementUSCETH />}
              tvlDSPL={ethlpDSPL}
              isFetched={isFethlp}
              totalApr={uscEthApr.totalApr.formatted}
              rewardIcon={ChiEthReward}
              isActive
              isFetchedAPR={isFetchedETHLPAPR}
              aprTooltip={
                <APRTooltip
                  items={[
                    {
                      icon: ChiIcon,
                      name: "CHI",
                      apr: uscEthApr.chiApr.formatted,
                    },
                    {
                      icon: StEth,
                      name: "stETH",
                      apr: uscEthApr.stEthApr.formatted,
                    },
                    {
                      icon: WeEth,
                      name: "weETH",
                      apr: uscEthApr.weEthApr.formatted,
                    },
                  ]}
                />
              }
            />

            <GenericRow
              firstElement={<FirstElementCHIETH />}
              tvlDSPL={chiEthTVL}
              isFetched={isFChiEthTvl}
              totalApr={chiEthApr.totalApr.formatted}
              rewardIcon={ChiEthReward}
              isActive
              isFetchedAPR={isChiEthFetched}
              aprTooltip={
                <APRTooltip
                  items={[
                    {
                      icon: ChiIcon,
                      name: "CHI",
                      apr: chiEthApr.chiApr.formatted,
                    },
                    {
                      icon: StEth,
                      name: "stETH",
                      apr: chiEthApr.stEthApr.formatted,
                    },
                    {
                      icon: WeEth,
                      name: "weETH",
                      apr: chiEthApr.weEthApr.formatted,
                    },
                  ]}
                />
              }
            />
            <FlexRow className="w-full  border-b bg-neutral-black-10 px-3 py-[6px]">
              <Typography
                type="body-small-regular"
                className="text-neutral-black-60"
              >
                Boosting
              </Typography>
            </FlexRow>

            <GenericRow
              firstElement={<FirstElementBoostedWstUSC />}
              tvlDSPL={boostedWstUscTVL}
              isFetched={isBoostedWstUscTVLFetched}
              totalApr={totalBoostedWstUscApr.formatted}
              rewardIcon={Usc}
              isActive={false}
              isFetchedAPR={isBoostedWstUscAprFetched}
              // aprTooltip={
              //   <APRTooltip
              //     items={[
              //       {
              //         icon: Usc,
              //         name: "USC",
              //         apr: stUscApr.viewValue || "N/A",
              //       },
              //       {
              //         icon: ChiIcon,
              //         name: "CHI",
              //         apr: boostedWstUscExtraApr.formatted || "N/A",
              //       },
              //     ]}
              //   />
              // }
            />

            <GenericRow
              firstElement={<FirstElementBoostedUscEth />}
              tvlDSPL={boostedUscEthTVL}
              isFetched={isBoostedUscEthTVLFetched}
              totalApr={totalBoostedUscEthApr.formatted}
              rewardIcon={ChiEthReward}
              isActive={false}
              isFetchedAPR={isBoostedUscEthAprFetched}
              // aprTooltip={
              //   <APRTooltip
              //     items={[
              //       {
              //         icon: ChiIcon,
              //         name: "CHI",
              //         apr: (
              //           Number(
              //             formatUnits(uscEthApr.chiApr.original || 0n, 8)
              //           ) +
              //           Number(
              //             formatUnits(boostedUscEthExtraApr.original || 0n, 8)
              //           )
              //         ).toFixed(2),
              //       },

              //       {
              //         icon: StEth,
              //         name: "stETH",
              //         apr: uscEthApr.stEthApr.formatted,
              //       },
              //       {
              //         icon: WeEth,
              //         name: "weETH",
              //         apr: uscEthApr.weEthApr.formatted,
              //       },
              //     ]}
              //   />
              // }
            />

            <GenericRow
              firstElement={<FirstElementBoostedChiEth />}
              tvlDSPL={boostedChiEthTVL}
              isFetched={isBoostedChiEthTVLFetched}
              totalApr={totalBoostedChiEthApr.formatted}
              rewardIcon={ChiEthReward}
              isActive={false}
              isFetchedAPR={isBoostedChiEthAprFetched}
              // aprTooltip={
              //   <APRTooltip
              //     items={[
              //       {
              //         icon: ChiIcon,
              //         name: "CHI",
              //         apr: (
              //           Number(
              //             formatUnits(chiEthApr.chiApr.original || 0n, 8)
              //           ) +
              //           Number(
              //             formatUnits(boostedChiEthExtraApr.original || 0n, 8)
              //           )
              //         ).toFixed(2),
              //       },
              //       {
              //         icon: StEth,
              //         name: "stETH",
              //         apr: chiEthApr.stEthApr.formatted,
              //       },
              //       {
              //         icon: WeEth,
              //         name: "weETH",
              //         apr: chiEthApr.weEthApr.formatted,
              //       },
              //     ]}
              //   />
              // }
            />
            <GenericRow
              firstElement={<FirstElementveCHI />}
              tvlDSPL={veChiTvlDSPL}
              isFetched={isVeChiFetched}
              totalApr={totalApr}
              isFetchedAPR={aprChiStakingIsFetched}
              rewardIcon={ChiEthReward}
              aprTooltip={
                <APRTooltip
                  items={[
                    {
                      icon: ChiIcon,
                      name: "CHI",
                      apr: chiApr,
                    },
                  ]}
                />
              }
              isActive
              isLastRow
            />
          </RowContainer>
        )}
      </div>
      {/* MOBILE */}
      <div className="flex flex-col gap-3 md:hidden">
        {/* HEADER */}
        <MobileRowContainer
          iconSrc={ChiIcon}
          name="CHI Protocol"
          tvl={"$" + formatMoney(sum)}
          apr={aprRange || "N/A"}
          reward={RewardTokens}
        />

        <TabProvider defaultTab="staking" overrideUrlSlug="liquidityTable">
          <FlexRow className="w-full h-[56px] p-[4px] justify-between rounded-[16px] bg-neutral-black-10 ">
            <LiquidityTabButton tab="staking">Staking</LiquidityTabButton>
            <LiquidityTabButton tab="boosting">Boosting</LiquidityTabButton>
          </FlexRow>
          <TabContent tab="staking">
            <MobileRowContainer
              iconSrc={StUsc}
              name="stUSC"
              tvl={"$" + uscTVL}
              apr={stUscApr.viewValue || "N/A"}
              reward={Usc}
            />
            <MobileRowContainer
              iconSrc={WstUsc}
              name="wstUSC"
              tvl={wstUscTVLDSPL}
              apr={stUscApr.viewValue || "N/A"}
              reward={Usc}
            />
            <MobileRowContainer
              iconSrc={UscEth}
              name="USC/ETH"
              tvl={ethlpDSPL}
              apr={uscEthApr.totalApr.formatted || "N/A"}
              reward={ChiEthReward}
            />
            <MobileRowContainer
              iconSrc={ChiEth}
              name="CHI/ETH"
              tvl={chiEthTVL}
              apr={chiEthApr.totalApr.formatted || "N/A"}
              reward={ChiEthReward}
            />
          </TabContent>
          <TabContent tab="boosting">
            <MobileRowContainer
              iconSrc={BoostedWstUsc}
              name="Boosted wstUSC"
              tvl={"$" + boostedWstUscTVL}
              apr={totalBoostedWstUscApr.formatted + "%" || "N/A"}
              reward={Usc}
            />
            <MobileRowContainer
              iconSrc={BoostedUscEth}
              name="Boosted USC/ETH"
              tvl={"$" + boostedUscEthTVL}
              apr={totalBoostedUscEthApr.formatted + "%" || "N/A"}
              reward={ChiEthReward}
            />
            <MobileRowContainer
              iconSrc={BoostedChiEth}
              name="Boosted CHI/ETH"
              tvl={"$" + boostedChiEthTVL}
              apr={totalBoostedChiEthApr.formatted + "%" || "N/A"}
              reward={ChiEthReward}
            />
            <MobileRowContainer
              iconSrc={VeChi}
              name="veCHI"
              tvl={"$" + veChiTvlDSPL}
              apr={aprData.totalApr + "%" || "N/A"}
              reward={ChiEthReward}
            />
          </TabContent>
        </TabProvider>
      </div>
    </>
  );
};
interface APRItem {
  icon: string;
  name: string;
  apr: string;
}

interface APRTooltipProps {
  items: APRItem[];
}

export const APRTooltip: React.FC<APRTooltipProps> = ({ items }) => {
  return (
    <InfoTooltip>
      <FlexCol className="w-[175px] gap-3 p-3">
        <Typography type="caption-medium" className="text-neutral-black-60">
          APR
        </Typography>
        {items.map((item, index) => (
          <FlexCol key={index} className="gap-[10px]">
            <FlexRow className="justify-between items-center">
              <FlexRow className="w-[69px] gap-2 items-center">
                <SVGWrapper src={item.icon} width={20} height={20} />
                <Typography
                  type="body-small-regular"
                  className="text-neutral-black-70"
                >
                  {item.name}
                </Typography>
              </FlexRow>
              <DisplayPercentage
                viewValue={item.apr}
                typography="body-small-bold"
                className="text-secondary"
              />
            </FlexRow>
          </FlexCol>
        ))}
      </FlexCol>
    </InfoTooltip>
  );
};

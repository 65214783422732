import { AccessControl, FlexCol } from "@shared";
import { CHIChangePriceForm } from "./CHIChangePriceForm";
import { StETHChangePriceForm } from "./StETHChangePriceForm";
import { USCChangePriceForm } from "./USCChangePriceForm";
import { WETHChangePriceForm } from "./WETHChangePriceForm";
import { ownerWhiteListedAddresses } from "@meta";

export default function OracleAdminPage() {
  return (
    <AccessControl allowedAddresses={ownerWhiteListedAddresses}>
      <FlexCol className="gap-10">
        <div className="w-2/3">
          <CHIChangePriceForm />
          <StETHChangePriceForm />
          <USCChangePriceForm />
          <WETHChangePriceForm />
        </div>
      </FlexCol>
    </AccessControl>
  );
}

const baseUrl = "";

export const RouterConfig = {
  Routes: {
    home: `${baseUrl}/`,
    stake: `${baseUrl}/stake`,
    liquidity: `${baseUrl}/liquidity`,
    rewards: `${baseUrl}/rewards`,
    boost: `${baseUrl}/boost`,
    vest: `${baseUrl}/vest`,
    analytics: `${baseUrl}/analytics`,
    admin: {
      reserveHolder: `${baseUrl}/admin/reserveHolder`,
      arbitrage: `${baseUrl}/admin/arbitrage`,
      oracles: `${baseUrl}/admin/oracles`,
      contractBalances: `${baseUrl}/admin/contract-balances`,
      priceDashboard: `${baseUrl}/admin/price-dashboard`,
      stakingManager: `${baseUrl}/admin/staking-manager`,
      lockingManager: `${baseUrl}/admin/locking-manager`,
      stUscManager: `${baseUrl}/admin/st-usc-manager`,
    },
  },
  Builder: {
    ilmDetails: (id: number) => `${baseUrl}/details/${id}`,
  },
};

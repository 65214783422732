import {
  Card,
  DisplayMoney,
  DisplayPercentage,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";

// Icons import
import Lamma from "@assets/layout/lamma.svg";
import Lizard from "@assets/layout/lizard.svg";
import Circle from "@assets/layout/circle.svg";
import CrownUSC from "@assets/tokens/crownUsc.svg";
import StUSC from "@assets/tokens/stUsc.svg";
// import GroupIcon from "@assets/layout/groupIcon.svg";
import { useTotalUscStaked } from "../../../../state/usc/useTotalUscStaked";
import { useFetchUscStakingAPR } from "../../../../state/usc/useFetchUscStakingAPR";

// Main component definition
export const ChiMetrics = () => {
  const uscTVL = useTotalUscStaked();

  const { aprDSPL, isFetchedAPR } = useFetchUscStakingAPR();

  return (
    <>
      {/* Card container for the Chi Metrics section */}
      <Card color="primary-10">
        {/* Main content container */}
        <FlexCol className="w-full p-4 gap-4">
          {/* Header section with title and icons */}
          <FlexRow className="justify-between items-center">
            <Typography type="body-large-bold">Chi Metrics</Typography>
            <FlexRow className="gap-[6px]">
              <a
                href="https://defillama.com/protocol/chi-protocol"
                className="hover:underline"
                target="_blank"
              >
                <SVGWrapper src={Lamma} width="24px" height="24px" />
              </a>
              <a
                href="https://www.coingecko.com/en/coins/usc"
                className="hover:underline"
                target="_blank"
              >
                <SVGWrapper src={Lizard} width="24px" height="24px" />
              </a>
              <a
                href="https://dune.com/quackery/chi-protocol-usc"
                className="hover:underline"
                target="_blank"
              >
                <SVGWrapper src={Circle} width="24px" height="24px" />
              </a>
            </FlexRow>
          </FlexRow>

          {/* TVL Display */}
          <FlexRow className="gap-3 items-center">
            <SVGWrapper src={CrownUSC} width={32} height={52} />
            <FlexCol className="gap-1">
              <DisplayMoney
                {...uscTVL}
                symbol="$"
                symbolPosition="before"
                typography="body-medium-medium"
              />
              <Typography
                type="caption-medium"
                className="text-neutral-black-60"
              >
                Total Staked USC
              </Typography>
            </FlexCol>
          </FlexRow>

          {/* stUSC APR Display */}
          <FlexRow className="gap-3 items-center">
            <SVGWrapper src={StUSC} width="32px" height="32px" />
            <FlexCol className="gap-1">
              <DisplayPercentage
                viewValue={aprDSPL.viewValue}
                isFetched={isFetchedAPR}
                symbolPosition="after"
                typography="body-medium-medium"
              />
              <Typography
                type="caption-medium"
                className="text-neutral-black-60"
              >
                stUSC APR
              </Typography>
            </FlexCol>
          </FlexRow>

          {/* Active Users Display */}
          {/* <FlexRow className="gap-3 items-center">
            <SVGWrapper src={GroupIcon} width="32px" height="32px" />
            <FlexCol className="gap-1">
              <DisplayValue viewValue="0" typography="body-medium-medium" />
              <Typography
                type="caption-medium"
                className="text-neutral-black-60"
              >
                Active Stakers
              </Typography>
            </FlexCol>
          </FlexRow>*/}
        </FlexCol>
      </Card>
    </>
  );
};

import { ownerWhiteListedAddresses } from "@meta";
import { AccessControl } from "@shared";
import { Prices } from "./Prices";

export default function PriceAdminPage() {
  return (
    <AccessControl allowedAddresses={ownerWhiteListedAddresses}>
      <div>
        <Prices />
      </div>
    </AccessControl>
  );
}

export const ownerWhiteListedAddresses = [
  "0xcdB8d92FA641106fdAEe3CCC6B53a029eDb9c458",
  "0xE36fF60a9Ae677c2d742a3DeddCA46d0fA999327",
];

export const vestingWhiteListedAddresses = [
  "0x471ed7960Ab787357D2E85Ec50f0e740364b8da7",
  "0x973659dD45786c1B8E8Fd01B6d40e36AD270654D",
  "0x1373D9DC494456d15375c5Fe18A761Ac491AF8C8",
  "0x8e85721916cBeBe9cF063Bf51Cb7689484290D09",
  "0x08F11Ea901Fb656B0Fd0fa0f8c9D898C008c5b8D",
  "0x3384c7CcB87a502494928C29703F26829c816699",
  "0x34B9f07201FDaaFC500Ae94e77a754a0F44ACa39",
  "0x603c3a3bbf3b71e37f47333730f5fe1918af2ff7",
  "0x0418A80b2a5b0900d10681DCa725925fC4aB868d",
  "0xaf885E1C09b52f355fCc46957f1e0F008B668d95",
  "0xa38cDB63c943E9481c9b87DB5C80f5AC333d16ED",
  "0xdD094c8f7FceDE76A7d724068fd73aCdF9b9Ea80",
  "0x92b868cbb48f16Eb360C05A2b75f36bD17541B47",
  "0xADaa95D836A5F03c29B25a76A5D5C3984D3634d8",
  "0xa38cDB63c943E9481c9b87DB5C80f5AC333d16ED",
  "0xaf885E1C09b52f355fCc46957f1e0F008B668d95",
  "0xcf25605fdb43ec072bbc1942d99fcd2034e5b362",
  "0x1373D9DC494456d15375c5Fe18A761Ac491AF8C8",
  "0x35a214b13c9E223B8D511f343aC8Fa94293233a1",
  "0xE2ca227aA276cDfabd851AA29672Bc2403c6E4E9",
  "0x92b868cbb48f16Eb360C05A2b75f36bD17541B47",
  "0x24fe521DF4C6dd958e9AEf7c17E076486aA06A1F",
  "0xf974984e37C99d49fabdCF6Fe493320545884cE4",
  "0x7C56B6CE6e358E8428630B27347Bd66e4a87A4cC",
  "0x42b5090a05f58e60414e21799366d3657ca198ca",
  "0x5B0bDd47130Fc032FD77D210B1e87aE251f9d441",
  "0xEc57c0cDe100f80c6075E5f443FdbA8BBEA631B8",
  "0xb4F0e0a939d3a1E2C15cdB5c28c6993775f5A07A",
  "0x477D1F7512499fAd35FfCb62a137044EACdbbA6A",
  "0x490e9Fee6708d49C37A168Ab512d7caA26FF601c",
  "0x6ecba7527448bb56caba8ca7768d271deaea72a9",
  "0x6137a2bcBA1930c6467D76361d15B716B46d3033",
  "0x6FCE96E53dD11bb27C3bA44bF45F4aB5158DA73C",
  "0x9e6eed1048eA14659F362583A8430C0b7C501789",
  "0x71BCC480C4A55c3326Ab4A98eF2aA22c7a98fB46",
  "0x8Bf8d526Bff34EBc0C6a94283e111deaA6374FFB",
  "0xd19A0bbf1a699B9A6090Cca514440B55f9794cbe",
  "0xE774EB3d7D3454EAFDfd60a1b9AC43E15B55c9de",
  "0xfd7dc8e5e533E2416040715F5a28Ed8fE63A3780",
  "0x91D28a45F925E0469d9ce65AF693EF25822a1567",
  "0x3409C712143Ec1B33dDbbEce51B912EA1B13F244",
  "0x6ef58bf8A858eAbFC554B1772dF0B14B5311E889",
  "0x2b254c947AE10FB306D81766464ca0D1A2f48BB5",
];

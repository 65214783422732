import { StakeProvider } from "../../../contexts/StakeProvider";
import { ContentWrapper727 } from "../../components/contentWrappers/ContentWrapper727";
import { Main } from "./components/Main";
import { RightSection } from "./components/rightSection/RightSection";

export const RewardPage = () => {
  return (
    <StakeProvider>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col flex-grow md:px-8 pb-8 ">
          <ContentWrapper727>
            <div className="mb-4">
              <Main />
            </div>
            <div className="block mt-4 md:hidden">
              <RightSection />
            </div>
          </ContentWrapper727>
        </div>
        {/* Fixed Right Section */}
        {/* <div className="w-[348px] mt-16" /> */}
        <div className="hidden md:block w-[348px] h-screen md:right-8 md:top-16">
          <RightSection />
        </div>
      </div>
    </StakeProvider>
  );
};

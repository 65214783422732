import {
  Card,
  DisplayMoney,
  DisplayPercentage,
  DisplayTokenAmount,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";
import { TotalSupplyChart } from "../charts/solvency/TotalSupplyChart";

import chiIcon from "@assets/tokens/chi.svg";
import vechiIcon from "@assets/tokens/veChi.svg";
import chiEthIcon from "@assets/tokens/chiEth.svg";
import { contractAddresses } from "../../../../meta";
import { ChiEthUniswapPoolReservesChart } from "../charts/chi/ChiEthUniswapPoolReservesChart";
import { useTotalChiSupply } from "../../../state/chi/useTotalChiSupply";
import { useFetchLockedChiDetailedAprDisplayable } from "../../../state/data-provider/useFetchLockedChiDetailedApr";
import { useFetchChiEthLpDetailedAPR } from "../../../state/data-provider/useFetchChiEthLpDetailedAPR";
import { useSpotPrice } from "../../../state/usc/useSpotPrice";

const spotConfig = {
  numberOfDecimals: 4,
};
// const holdersQueryId = 3802318;
export const CHITab = () => {
  const { viewValue: chiSpotDisplayPrice, isFetched: isChiSpotFetched } =
    useSpotPrice(contractAddresses.CHI, contractAddresses.WETH, spotConfig);
  const totalSupply = useTotalChiSupply();

  // const {
  //   data: initialData,
  //   isFetched: isInitialFetched,
  //   isSuccess: isInitialSuccess,
  // } = useQuery({
  //   queryKey: [buildQueryKey(holdersQueryId)],
  //   queryFn: () => fetchFromApi(holdersQueryId),
  //   staleTime: CHART_STALE_TIME,
  //   retry: true,
  // });
  // console.log({ initialData });

  const { totalApr, isFetchedAPR: isVeChiFetched } =
    useFetchLockedChiDetailedAprDisplayable();

  const { detailedApr: chiEthApr, isFetchedAPR: isChiEthFetched } =
    useFetchChiEthLpDetailedAPR();

  return (
    <div className="flex flex-col gap-8">
      <Card hasShadowInstead hasBorder>
        <FlexCol className="p-4 gap-6">
          <Typography type="body-small-medium">CHI Stats</Typography>
          <FlexRow className=" justify-center items-center">
            <FlexRow className="md:w-[740px]">
              <FlexRow className="flex-col md:flex-row w-full justify-center items-center min-h-[244px] text-center">
                <FlexCol className="md:w-1/3">
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={chiIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        CHI in Circulation
                      </Typography>
                    </FlexRow>
                    <DisplayTokenAmount
                      {...totalSupply}
                      typography="body-large-medium"
                    />
                  </FlexCol>
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={vechiIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        veCHI APR
                      </Typography>
                    </FlexRow>
                    <DisplayPercentage
                      viewValue={totalApr}
                      isLoading={!isVeChiFetched}
                      typography="body-large-medium"
                    />
                  </FlexCol>
                </FlexCol>
                <FlexCol className="md:w-1/3 justify-center items-center min-h-[244px] text-center mx-5">
                  <div className="flex flex-col items-center justify-center text-center bg-neutral-black-10 rounded-full w-[200px] h-[200px]">
                    <FlexRow className="gap-2 items-center">
                      <SVGWrapper width={24} height={24} src={chiIcon} />
                      <Typography type="body-large-medium">
                        CHI Price
                      </Typography>
                    </FlexRow>
                    <DisplayMoney
                      viewValue={chiSpotDisplayPrice}
                      isLoading={!isChiSpotFetched}
                      className="text-primary "
                      typography="h3"
                    />
                  </div>
                </FlexCol>
                <FlexCol className="md:w-1/3">
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        N. of CHI Holders
                      </Typography>
                    </FlexRow>
                    <Typography type="body-large-medium">199</Typography>
                  </FlexCol>
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={30} height={30} src={chiEthIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        CHI/ETH LP APR
                      </Typography>
                    </FlexRow>
                    <DisplayPercentage
                      viewValue={chiEthApr.totalApr.formatted}
                      isLoading={!isChiEthFetched}
                      typography="body-large-medium"
                    />
                  </FlexCol>
                </FlexCol>
              </FlexRow>
            </FlexRow>
          </FlexRow>
        </FlexCol>
      </Card>
      <div className="flex flex-col gap-8 md:min-h-200px lg:min-h-[500px]">
        <Card hasShadowInstead>
          <TotalSupplyChart
            name="CHI Supply"
            icon={<SVGWrapper width={20} height={20} src={chiIcon} />}
          />
        </Card>
        <Card hasShadowInstead>
          <ChiEthUniswapPoolReservesChart
            name="CHI/ETH Uniswap Pool Reserves"
            icon={<SVGWrapper width={20} height={20} src={chiIcon} />}
          />
        </Card>
      </div>
    </div>
  );
};

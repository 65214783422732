import {
  Card,
  FlexCol,
  Typography,
  FlexRow,
  DisplayMoney,
  SVGWrapper,
  DisplayPercentage,
} from "@shared";
import { UscEthUniswapPoolReservesChart } from "../../charts/reserves/UscEthUniswapPoolReservesChart";
import { contractAddresses } from "../../../../../meta";
import { useSpotPrice } from "../../../../state/usc/useSpotPrice";

import stEthIcon from "@assets/tokens/stEth.svg";
import wethIcon from "@assets/tokens/weth.svg";
import weEthIcon from "@assets/tokens/weEth.svg";
import uscIcon from "@assets/tokens/usc.svg";
import stUscIcon from "@assets/tokens/stUsc.svg";
import uscEthIcon from "@assets/tokens/uscEth.svg";
// import { useTotalUscSupply } from "../../../../state/usc/useTotalUscSupply";
import { useReserveValue } from "../../../../state/reserveholder/useOverviewData";
import { useFetchUscStakingAPR } from "../../../../state/usc/useFetchUscStakingAPR";
import { useFetchUscEthLpDetailedAPR } from "../../../../state/data-provider/useFetchUscEthLpDetailedAPR";
import { useTotalUscSupply } from "../../../../state/usc/useTotalUscSupply";
import { USCTotalSupplyChart } from "../../charts/solvency/USCTotalSupplyChart";

export const USCContent = () => {
  const uscSpotPrice = useSpotPrice(contractAddresses.USC);
  // const totalSupply = useTotalUscSupply();
  const { aprDSPL } = useFetchUscStakingAPR();

  const reserveValue = useReserveValue();

  const { detailedApr: uscEthApr, isFetchedAPR: isFetchedETHLPAPR } =
    useFetchUscEthLpDetailedAPR();

  const totalSupply = useTotalUscSupply();

  return (
    <div className="flex flex-col gap-8">
      <Card hasShadowInstead hasBorder>
        <FlexCol className="p-4 gap-6">
          <Typography type="body-small-medium">USC Stats</Typography>
          <FlexRow className=" justify-center items-center">
            <FlexRow className="max-w-[640px]">
              <FlexRow className="flex-col md:flex-row w-full justify-center items-center min-h-[244px] text-center">
                <FlexCol className="md:w-1/3">
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <FlexRow className="gap-2">
                        <SVGWrapper width={20} height={20} src={stEthIcon} />
                        <SVGWrapper
                          className="ml-[-15px]"
                          width={20}
                          height={20}
                          src={wethIcon}
                        />
                        <SVGWrapper
                          className="ml-[-15px]"
                          width={20}
                          height={20}
                          src={weEthIcon}
                        />
                      </FlexRow>
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        Reserves value
                      </Typography>
                    </FlexRow>
                    <DisplayMoney
                      {...reserveValue}
                      typography="body-large-medium"
                    />
                  </FlexCol>
                  <FlexCol className="py-2 md:py-8  gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={stUscIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        stUSC APR
                      </Typography>
                    </FlexRow>
                    <DisplayPercentage
                      {...aprDSPL}
                      viewValue={aprDSPL.viewValue}
                      symbolPosition="after"
                      typography="body-large-medium"
                    />
                  </FlexCol>
                </FlexCol>
                <FlexCol className="md:w-1/3 justify-center items-center min-h-[244px] text-center mx-5">
                  <div className="flex flex-col items-center justify-center text-center bg-neutral-black-10 rounded-full w-[200px] h-[200px]">
                    <FlexRow className="gap-2 items-center">
                      <SVGWrapper width={24} height={24} src={uscIcon} />
                      <Typography type="body-large-medium">
                        USC Price
                      </Typography>
                    </FlexRow>
                    <DisplayMoney
                      {...uscSpotPrice}
                      className="text-primary "
                      typography="h3"
                    />
                  </div>
                </FlexCol>
                <FlexCol className="md:w-1/3">
                  <FlexCol className="py-2 md:py-8  gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={uscIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        USC Market Cap
                      </Typography>
                    </FlexRow>

                    <DisplayMoney
                      {...totalSupply}
                      typography="body-large-medium"
                    />
                  </FlexCol>
                  <FlexCol className="py-2 md:py-8  gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2 items-center">
                      <SVGWrapper width={30} height={30} src={uscEthIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        USC/ETH LP APR
                      </Typography>
                    </FlexRow>
                    <DisplayPercentage
                      viewValue={uscEthApr.totalApr.formatted}
                      isLoading={!isFetchedETHLPAPR}
                      typography="body-large-medium"
                    />
                  </FlexCol>
                </FlexCol>
              </FlexRow>
            </FlexRow>
          </FlexRow>
        </FlexCol>
      </Card>
      <div className="flex flex-col 2xl:flex-row gap-8 md:min-h-200px lg:min-h-[500px]">
        <Card hasShadowInstead className="w-full 2xl:w-1/2">
          <USCTotalSupplyChart
            name="USC Supply"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
        <Card hasShadowInstead className="w-full 2xl:w-1/2">
          <UscEthUniswapPoolReservesChart
            name="USC/ETH Uniswap Pool Reserves"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
      </div>
    </div>
  );
};

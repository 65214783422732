import { AccessControl, Divider, FlexCol } from "@shared";

import { StartStakingForm } from "./StartStakingForm";
import { ConfigureRewardTokenForm } from "./ConfigureRewardTokenForm";
import { StakingStatusList } from "./StakingStatusList";
import RewardStatusOverview from "./RewardStatusCard";
import { ChangeTimeForm } from "./ChangeTimeForm";
import { ownerWhiteListedAddresses } from "@meta";

export default function StakingManagerPage() {
  return (
    <AccessControl allowedAddresses={ownerWhiteListedAddresses}>
      <FlexCol className="gap-10">
        <div className="w-2/3">
          <ChangeTimeForm />
        </div>
        <div className="w-2/3">
          <StakingStatusList />
        </div>
        <Divider />
        <div className="w-2/3">
          <StartStakingForm />
        </div>
        <Divider />
        <div className="w-2/3">
          <RewardStatusOverview />
        </div>
        <div className="w-2/3">
          <ConfigureRewardTokenForm />
        </div>
      </FlexCol>
    </AccessControl>
  );
}

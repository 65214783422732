import { LockingManagerAbi } from "@meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  MyFormProvider,
  FlexCol,
  Button,
} from "@shared";
import { useForm } from "react-hook-form";
import { Address, parseUnits } from "viem";

export const UpdateRewardsPerEpoch: React.FC<{
  name?: string;
  address: Address;
}> = ({ name, address }) => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: configureRewardToken, isPending } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await configureRewardToken(
      {
        abi: LockingManagerAbi,
        address: address,
        functionName: "updateRewardsPerEpoch",
        args: [parseUnits(data.rewardsPerEpoch, 18)],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: (
              <Typography>
                updateRewardsPerEpoch successfully updated
              </Typography>
            ),
          });
        },
      }
    );
  };

  return (
    <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography>Configure rewardsPerEpoch for {name}</Typography>
      <FlexCol className="gap-3">
        <input
          {...methods.register("rewardsPerEpoch", { required: true })}
          placeholder="rewardsPerEpoch"
          className="input input-bordered w-full"
        />

        <Button type="submit" loading={isPending}>
          Configure rewardsPerEpoch for {name}
        </Button>
      </FlexCol>
    </MyFormProvider>
  );
};

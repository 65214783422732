import { useFetchWstUscPrice } from "../../../../state/wstUsc/useFetchWstUscPrice";
import { FAQsComponent } from "../../../../components/info/FAQsComponent";
import InfoItems from "../../../../components/info/InfoItems";
import { InfoTabButton } from "../../../../components/tab/InfoTabButton";

import {
  Button,
  FlexCol,
  FlexRow,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";

export const InfoSection = () => {
  const { exchangeRate, isFetched } = useFetchWstUscPrice();

  const infos = [
    "stUSC is a yield-bearing stablecoin that automatically earns rebase rewards from Chi Protocol’s arbitrage revenue with every Ethereum block (≈ 12 sec).",
    "Rewards start as soon as stUSC is in your wallet and continue as long as you hold it.",
    "The amount of stUSC increases over time while staked. You can unstake stUSC at any time, but you stop earning rewards once you do.",
    "stUSC maintains liquidity and provides flexibility across DeFi, unlocking additional yield opportunities.",
    "There exist no cooldown periods or withdrawal queues. Chi Protocol charges no fees for staking/unstaking, aside from gas fees of the underlying network.",
    "stUSC can be wrapped into wstUSC, a non-rebasing, value-accruing version.",
    `The exchange rate between USC and stUSC is fixed at 1:1, while the dynamic rate between stUSC and wstUSC is based on accumulated rewards (currently 1 stUSC = 
    ${isFetched ? exchangeRate : "loading..."}
     wstUSC).`,
  ];

  const faqItems = [
    {
      title: "How do I stake USC?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          To stake USC, first mint or buy USC. You can mint and stake in one
          transaction via the Mint page. Once staked, you'll receive stUSC in
          your wallet and start earning rebase rewards automatically.
        </Typography>
      ),
    },
    {
      title: "What is the difference between stUSC and wstUSC?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          <ul className="list-disc list-inside">
            <li>
              <strong>stUSC (Rebase Version):</strong> Automatically increases
              in balance over time by accruing rewards with each block.
            </li>
            <li>
              <strong>wstUSC (Non-Rebase Version):</strong> Holds a fixed
              balance but increases in value, providing predictable value
              growth.
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      title: "When will I start earning rewards?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          Rewards start immediately after acquiring stUSC, distributed with
          every block (≈12 seconds). You’ll earn as long as you hold stUSC, and
          can unstake anytime, stopping rewards.
        </Typography>
      ),
    },
    {
      title: "Is there any fee to stake and unstake USC?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          No, there are no fees for staking or unstaking USC.
        </Typography>
      ),
    },
    {
      title: "Where does the yield come from?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          stUSC and wstUSC rewards come from Chi Protocol’s Dual Stability
          Mechanism (DSM), which generates arbitrage revenue when maintaining
          the collateral ratio and responding to demand shifts.
        </Typography>
      ),
    },
  ];

  return (
    <FlexCol className="w-full p-6 gap-6 rounded-2xl border-[1px] border-neutral-black-30">
      <TabProvider defaultTab="key-insight" overrideUrlSlug="infoTab">
        <FlexRow className="w-[245px] h-[56px] p-[4px] justify-between rounded-[16px] bg-neutral-black-10 ">
          <InfoTabButton tab="key-insight">Key Insight</InfoTabButton>
          <InfoTabButton tab="faq">FAQ</InfoTabButton>
        </FlexRow>
        <TabContent tab="key-insight">
          <InfoItems infos={infos} />
        </TabContent>
        <TabContent tab="faq">
          <FlexCol className="gap-6">
            <FAQsComponent items={faqItems} />
            <Button
              onClick={() =>
                window.open(
                  "https://chi-protocol.gitbook.io/chi-docs",
                  "_blank"
                )
              }
              size="small"
              className="w-[170px] h-10  bg-primary text-white rounded-md"
            >
              <Typography type="body-small-bold">Documentation</Typography>
            </Button>
          </FlexCol>
        </TabContent>
      </TabProvider>
    </FlexCol>
  );
};

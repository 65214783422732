import { FlexCol } from "@shared";
import { ReserveAssets } from "../../../../components/rightSection/reserve-assets/ReserveAssets";

import { YieldComparison } from "../../../../components/rightSection/yield/YieldComparison";
import { ChiMetrics } from "../../../../components/rightSection/ChiMetrics";
import { Analytics } from "../../../../components/rightSection/Analytics";
import { SavingsCalculator } from "../../../../components/rightSection/SavingsCalculator";
import { StakeComponent } from "../../../../components/rightSection/stake-component/StakeComponent";
import { Countdown } from "../../../../components/rightSection/countdown/Countdown";

export const RightSection = () => {
  return (
    <FlexCol className="w-full">
      <FlexCol className="gap-4">
        <ChiMetrics />
        <ReserveAssets />
        <StakeComponent />
        <Countdown
          initialStartingDatetime={new Date("2024-03-11T09:00:00+01:00")}
          durationInDays={7}
        />
        <Analytics />
        <SavingsCalculator />
        <YieldComparison />
      </FlexCol>
    </FlexCol>
  );
};

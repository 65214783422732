import { useForm } from "react-hook-form";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
} from "../../../../shared";
import { parseUnits } from "viem";
import { useWingsApprove } from "../../../state/common/useWingsApprove";

export const RewardUSC: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit, watch } = methods;
  const amount = watch("amount");

  const { writeContractAsync: rewardUSC, isPending: isRewarding } =
    useWingsContractWrite();

  const { approveAsync, isApproved } = useWingsApprove(
    contractAddresses.USC,
    contractAddresses.Arbitrage,
    parseUnits(amount || "0", 18)
  );

  const onSubmit = async (data: any) => {
    if (!isApproved) {
      await approveAsync(parseUnits(data.amount || "0", 18));
      return;
    }
    await rewardUSC(
      {
        abi: ArbitrageAbi,
        address: contractAddresses.Arbitrage,
        functionName: "rewardUSC",
        args: [parseUnits(data.amount || "0", 18)],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>USC tokens rewarded successfully</Typography>,
          });
        },
      }
    );
  };

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <Typography>Arbitrage / rewardUSC /</Typography>
          <FlexCol className="gap-3">
            <input
              step="any"
              {...methods.register("amount")}
              placeholder="USC Amount"
              type="text"
              className="input input-bordered w-full"
            />
            <Button type="submit" loading={isRewarding}>
              {isApproved ? "Reward USC" : "Approve"}
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};

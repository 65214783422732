import { AccessControl } from "../../../shared/components/accessControl/AccessControl";
import { ContentWrapper727 } from "../../components/contentWrappers/ContentWrapper727";
import { InfoSection } from "./components/main/InfoSection";
import { Main } from "./components/main/Main";
import { RightSection } from "./components/rightSection/RightSection";
import { vestingWhiteListedAddresses } from "@meta";

export const VestPage = () => {
  return (
    <AccessControl allowedAddresses={vestingWhiteListedAddresses}>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col flex-grow md:px-8 pb-8 ">
          <ContentWrapper727>
            <div className="mb-4">
              <Main />
            </div>

            <div>
              <InfoSection />
            </div>
            <div className="block mt-4 md:hidden">
              <RightSection />
            </div>
          </ContentWrapper727>
        </div>

        {/* Right Section for Larger Screens */}
        <div className="hidden md:block w-[348px] h-screen md:right-8 md:top-16">
          <RightSection />
        </div>
      </div>
    </AccessControl>
  );
};

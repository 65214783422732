import { useReadContract } from "wagmi";
import { CHIAbi, contractAddresses } from "../../../meta";
import { displayTokens } from "../../../utils";

export const useTotalChiSupply = () => {
  const { data: totalSupply, ...restTotalSupply } = useReadContract({
    abi: CHIAbi,
    address: contractAddresses.CHI,
    functionName: "totalSupply",
  });
  // const { formattedPrice } = useGetPriceByAddress(contractAddresses.CHI);

  const viewValue = displayTokens(totalSupply, {});

  return {
    ...restTotalSupply,
    viewValue,
    originalValue: totalSupply,
  };
};

import { contractAddresses, USCStakingAbi } from "@meta";
import { displayTokens } from "@utils";
import { useReadContract } from "wagmi";
import { useSpotPrice } from "./useSpotPrice";

export const useTotalUscStaked = () => {
  const { data: totalUSCSTaked, isFetched } = useReadContract({
    abi: USCStakingAbi,
    functionName: "totalStaked",
    address: contractAddresses.USCStaking,
    args: [],
  });

  const { spotPrice } = useSpotPrice(contractAddresses.USC);
  const formattedPrice = spotPrice ? parseFloat(spotPrice) : undefined;

  return {
    valueDSPL: displayTokens(totalUSCSTaked, {
      formattedPrice,
      displayInDollars: true,
      hideTokenAmount: true,
    }),
    viewValue: displayTokens(totalUSCSTaked, {
      formattedPrice,
      displayInDollars: true,
      hideTokenAmount: true,
    }),
    isFetched,
  };
};

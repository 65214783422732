import { FAQsComponent } from "../../../../components/info/FAQsComponent";
import InfoItems from "../../../../components/info/InfoItems";
import { InfoTabButton } from "../../../../components/tab/InfoTabButton";

import {
  Button,
  FlexCol,
  FlexRow,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";

const infos = [
  <>
    APRs are based on current rewards, epoch, and TVL, and are annualised. Visit
    the{" "}
    <a
      href="https://chi-protocol.gitbook.io/chi-docs/resources/apr-formulas"
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary"
    >
      APR Formulas
    </a>{" "}
    page for details.
  </>,
  "Rewards in Chi Protocol are distributed in CHI, USC, stETH, and weETH. CHI rewards come from the initial token allocation to community incentives, stETH and weETH from protocol reserves, and USC from arbitrage revenue.",
  "Staking rewards are received every block (≈12 sec), while boosting rewards are distributed daily (≈6 PM CET), with CHI boosting rewards released weekly (Sunday ≈12 AM CET).",
  "LP staking and boosting rewards are claimable instantly, while stUSC and wstUSC rewards are automatic. CHI boosting rewards are locked until the boost period ends.",
  "Pair CHI with ETH to stake LP tokens and earn stETH, weETH, and CHI. Boosting LP tokens increases CHI rewards. Boosting CHI converts it to veCHI, earning stETH and CHI.",
];

const faqItems = [
  {
    title: "What is the difference between staking and boosting?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Staking keeps your position liquid and earns standard rewards. Boosting
        unlocks extra opportunities and additional rewards on top of staking
        yields.
      </Typography>
    ),
  },
  {
    title: "What do I earn by staking and boosting tokens in Chi Protocol?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Staking earns rewards in USC, CHI, and external rewards in LSTs and
        LRTs. Boosting adds extra CHI rewards to the underlying staking returns.
      </Typography>
    ),
  },
  {
    title: "How often do I receive rewards?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        <ul className="list-disc list-inside">
          <li>
            <strong>Staking:</strong> Rewards (USC, CHI, stETH, weETH) are
            distributed continuously with each Ethereum block (≈12 sec).
          </li>
          <li>
            <strong>Boosting:</strong> Extra CHI rewards are distributed daily
            (≈6 PM CET), while staking rewards are received per block. CHI
            boosting rewards are distributed weekly (Sunday ≈12 am CET).
          </li>
        </ul>
      </Typography>
    ),
  },
  {
    title: "What extra incentives do I earn by boosting?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Boosting gives extra CHI rewards on top of the staking yields.
      </Typography>
    ),
  },
  {
    title: "What is the minimum and maximum boost time?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        <ul className="list-disc list-inside">
          <li>wstUSC, CHI/ETH, USC/ETH: Min 1 week, max 1 year.</li>
          <li>veCHI: Min 4 weeks, max 4 years.</li>
        </ul>
      </Typography>
    ),
  },
  {
    title: "When can I stake and unstake tokens?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        You can stake and unstake at any time. For boosted positions, you must
        wait until the boost period ends to unstake.
      </Typography>
    ),
  },
  {
    title: "How are APRs calculated?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        APRs are based on current rewards, epochs, and TVL, and are annualised
        accordingly. For more details, visit the{" "}
        <a
          href="https://chi-protocol.gitbook.io/chi-docs/resources/apr-formulas"
          className="underline text-primary"
          target="_blank"
        >
          APR Formulas
        </a>{" "}
        page in the documentation.
      </Typography>
    ),
  },
];

export const InfoSection = () => {
  return (
    <FlexCol className="w-full p-6 gap-6 rounded-2xl border-[1px] border-neutral-black-30">
      <TabProvider defaultTab="key-insight" overrideUrlSlug="infoTab">
        <FlexRow className="w-[245px] h-[56px] p-[4px] justify-between rounded-[16px] bg-neutral-black-10 ">
          <InfoTabButton tab="key-insight">Key Insight</InfoTabButton>
          <InfoTabButton tab="faq">FAQ</InfoTabButton>
        </FlexRow>
        <TabContent tab="key-insight">
          <InfoItems infos={infos} />
        </TabContent>
        <TabContent tab="faq">
          <FlexCol className="gap-6">
            <FAQsComponent items={faqItems} />
            <Button
              onClick={() =>
                window.open(
                  "https://chi-protocol.gitbook.io/chi-docs",
                  "_blank"
                )
              }
              size="small"
              className="w-[170px] h-10  bg-primary text-white rounded-md"
            >
              <Typography type="body-small-bold">Documentation</Typography>
            </Button>
          </FlexCol>
        </TabContent>
      </TabProvider>
    </FlexCol>
  );
};

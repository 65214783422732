import { TabProvider, FlexCol } from "@shared";
import { USCContent } from "./USCContent";

type Tabs = "USC" | "stUSC" | "wstUSC";

export const USCTab = () => {
  return (
    <div className="flex flex-col gap-8">
      <TabProvider<Tabs> defaultTab={"USC"} overrideUrlSlug="uscSubTab">
        <FlexCol className="w-full gap-8 ">
          {/* Tab Buttons */}
          {/* <FlexRow className="bg-white">
            <RoundTabButton<Tabs> tab="USC" className="flex-1 py-2">
              USC
            </RoundTabButton>
            <RoundTabButton<Tabs> tab="stUSC" className="flex-1 py-2">
              stUSC
            </RoundTabButton>
            <RoundTabButton<Tabs> tab="wstUSC" className="flex-1 py-2">
              wstUSC
            </RoundTabButton>
          </FlexRow> */}

          <USCContent />
          {/* <TabContent<Tabs> tab="USC">
          </TabContent>
          <TabContent<Tabs> tab="stUSC">
            <StUSCContent />
          </TabContent>
          <TabContent<Tabs> tab="wstUSC">
            <WstUSCContent />
          </TabContent> */}
        </FlexCol>
      </TabProvider>
    </div>
  );
};

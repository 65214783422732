import React from "react";
import { useStakeContext } from "../../../../contexts/StakeProvider";
import { useTab } from "../../../../shared";

export const TabController: React.FC<{}> = ({}) => {
  const { activeTab } = useStakeContext();

  const { setActiveTab } = useTab();

  React.useEffect(() => {
    setActiveTab(activeTab);
  }, [activeTab, setActiveTab]);

  return <></>;
};

import { RewardControllerBalances } from "./RewardControllerBalances";
import { DSOBalances } from "./DSO-OCHI-Balances";
import { TreasuryBalances } from "./TreasuryBalances";
import { ReserveHolderBalances } from "./ReserveHolderBalances";
import { PageContent } from "../components/PageContent/PageContent";
import { ownerWhiteListedAddresses } from "@meta";
import { AccessControl } from "@shared";

export default function ContractBalancesAdminPage() {
  return (
    <AccessControl allowedAddresses={ownerWhiteListedAddresses}>
      <PageContent className="grid grid-cols-2 gap-2">
        <RewardControllerBalances />
        <DSOBalances />
        <TreasuryBalances />
        <ReserveHolderBalances />
      </PageContent>
    </AccessControl>
  );
}

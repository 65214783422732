import { Address } from "viem";
import dotenv from "dotenv";
dotenv.config();

const useTestAddresses =
  import.meta.env.VITE_USE_TEST_CONTRACT_ADDRESSES === "true";

interface ContractAddresses {
  USC: Address;
  CHI: Address;
  USC_ETH_LP: Address;
  CHI_ETH_LP: Address;
  ETH_USD_oracle: Address;
  stETH_USD_oracle: Address;
  USC_USD_oracle: Address;
  CHI_USD_oracle: Address;
  PriceFeedAggregator: Address;
  ChiStaking: Address;
  ChiLocking: Address;
  ChiVesting: Address;
  USCStaking: Address;
  USCStakingOld: Address;
  WstUSC: Address;
  StakingManager: Address;
  ReserveHolder: Address;
  ReserveHolderDataProvider: Address;
  RewardController: Address;
  TimeWeightedBonding: Address;
  Arbitrage: Address;
  veCHI: Address;
  WETH: Address;
  stETH: Address;
  Uniswapv2Router: Address;
  DataProvider: Address;
  OCHI: Address;
  USC_ETH_LP_rewards: Address;
  CHI_ETH_LP_rewards: Address;
  USC_ETH_LP_Staking: Address;
  CHI_ETH_LP_Staking: Address;

  USC_ETH_LP_Locking: Address;
  CHI_ETH_LP_Locking: Address;
  WST_USC_Locking: Address;
  UniswapV2TwapOracle: Address;
  IDO: Address;
  TestpageHelper: Address;
  Treasury: Address;
  WETH_ORACLE: Address;
  StETH_ORACLE: Address;
  USC_ORACLE: Address;
  CHI_ORACLE: Address;
  USDT: Address;
  DAI: Address;
  USDC: Address;
  UniswapV3Router: Address;
  ZAP: Address;
  ETH: Address;
  weETH: Address;
  FRAX: Address;
  USDe: Address;
  USC_ETH_LP_StakingOld: Address;
  CHI_ETH_LP_StakingOld: Address;
  CHI_LOCKING_APR: Address;
  // lpRewards: Address;
}

const contractAddressesTest: ContractAddresses = {
  ETH: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  weETH: "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
  USC: "0x38547D918b9645F2D94336B6b61AEB08053E142c",
  CHI: "0x3b21418081528845a6DF4e970bD2185545b712ba",
  ETH_USD_oracle: "0x49B05F8479Fa887Fa149A8175D3f6d1028102A23",
  stETH_USD_oracle: "0xa284AE9b63FE746F500f3163318C2B1A23DeaC68",
  USC_USD_oracle: "0x488490185c32f183A39d787ED311ee8EfAd715C9",
  CHI_USD_oracle: "0x7945142B3ef3914b038B65c940DEFFF419AE29f0",
  PriceFeedAggregator: "0xb3a36232ECc1da6C8D0d3f417E00406566933bD0",
  ChiStaking: "0xaB1dCa1C0f948c268652eedC676966002Ae241c6",
  ChiLocking: "0xE3dD17ff009bAC84e32130fcA5f01C908e956603",

  USC_ETH_LP: "0x88d1fFB9F94Fc881ea0D83Dddcdb196EE9DA8739",
  CHI_ETH_LP: "0x9f93F419d0267877247A39b4eb6b2775AbAC6bdc",
  USC_ETH_LP_Staking: "0x8f0d6eA886301400071Ced8EC171CC2208760df6",
  CHI_ETH_LP_Staking: "0x254BE9178572015619e795809AF18b8Ed9A71FaE",
  USC_ETH_LP_StakingOld: "0x85CD2803223C864D625b1D289fDD3Cee7e4bB307",
  CHI_ETH_LP_StakingOld: "0xD66DAbE0c898Ec74DA03AE4e90b9c051408685e6",
  USC_ETH_LP_Locking: "0x98E95a5E54E04A4a2dfCFd38dF924E1434d6E005",
  CHI_ETH_LP_Locking: "0x2a948bEB6b741ddCF21BF87644fB49fA96ec43b0",
  WST_USC_Locking: "0x111572550554E5C82e33cfEca2039Ce2Df015170", // todo: this is incorect

  ChiVesting: "0x426DBAa2B33cE1B833C13b72503F5128AFef79fC",
  USCStaking: "0x6Dd9738FB2277fcD6B2f5eb5FdaAaeC32e702761",
  USCStakingOld: "0x60aBb55c8488698153dB0AF2af362EdB25A505e3",
  WstUSC: "0xa90f874eB15a13d7a913326Ef41963AaDA9111dd",
  StakingManager: "0xA3A4E2e8A65aF27019f40347e0784Ba941f14358",
  ReserveHolder: "0xc36303ef9c780292755B5a9593Bfa8c1a7817E2a",
  ReserveHolderDataProvider: "0x9494c86Ae811edE33A409Ba49Eca0A2b531F2768",
  RewardController: "0xb9e08d13d2694B561a5033ACa07Ba7dEa49D0258",

  USC_ETH_LP_rewards: "0xc34ac319acAd3F8470a781921535856194726cb7",
  CHI_ETH_LP_rewards: "0x3CCC8E02eB810D84DA0107f0bD6Dd90eE76b46dE",
  OCHI: "0x8c3904bC848F6beff87c37F0414430a54B335Daa",
  Arbitrage: "0xC6aC3b91dA8a8b491b70876AFd8710c9e558dE16",
  veCHI: "0x237675D4DB33e505d1BB2Ed442B99Ab03886E2Fd",
  TestpageHelper: "0x088BDd5cEdc79043cdBCc8772ae224b0aeDf9B0C",
  Treasury: "0xA4b80F3ba55A4ed1316b173F64D5D036D7F83D4d",

  DataProvider: "0x2736e82C8f3D514Aba4907b8c730c40b18Ccc033",

  TimeWeightedBonding: "0x403f72d4A8F8b0779AfBC8bE2f69fB296e7f8DA3",
  WETH_ORACLE: "0x1213c98d9F3b28B313Ba5BDCc6261dBFc486bd75",
  StETH_ORACLE: "0x9Cf7fc9563Afc93BefE61CA810c1b440d1bD7323",
  USC_ORACLE: "0xdf9f3c6EB3357C8CE9f3706B9a6E430Ad593345b",
  CHI_ORACLE: "0xA2506b632af2f8e6131Fb1755F7A75F9160Ae949",
  IDO: "0x4794d2EAFf61cC414b8e077C2305396A550b1B83",
  // common
  stETH: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
  WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  Uniswapv2Router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  UniswapV2TwapOracle: "0xedAeDD22e653c504ff6806bf61664292848eB26e",
  UniswapV3Router: "0xe592427a0aece92de3edee1f18e0157c05861564",
  DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
  //ZAP
  USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  USDe: "0x4c9EDD5852cd905f086C759E8383e09bff1E68B3",
  FRAX: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
  ZAP: "0x490db60cfe9ea8a95a34e027a7f86bf9092e5e00",
  CHI_LOCKING_APR: "0x6e46B9bf4a983A44787ce028698d86D472b8A1bA",
};

export const contractAddresses: ContractAddresses = useTestAddresses
  ? contractAddressesTest
  : {
      ETH: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      weETH: "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
      USC: "0x38547D918b9645F2D94336B6b61AEB08053E142c",
      CHI: "0x3b21418081528845a6DF4e970bD2185545b712ba",
      ETH_USD_oracle: "0x49B05F8479Fa887Fa149A8175D3f6d1028102A23",
      stETH_USD_oracle: "0xa284AE9b63FE746F500f3163318C2B1A23DeaC68",
      USC_USD_oracle: "0x488490185c32f183A39d787ED311ee8EfAd715C9",
      CHI_USD_oracle: "0x7945142B3ef3914b038B65c940DEFFF419AE29f0",
      PriceFeedAggregator: "0xb3a36232ECc1da6C8D0d3f417E00406566933bD0",
      ChiStaking: "0xaB1dCa1C0f948c268652eedC676966002Ae241c6",
      ChiLocking: "0xE3dD17ff009bAC84e32130fcA5f01C908e956603",

      USC_ETH_LP: "0x88d1fFB9F94Fc881ea0D83Dddcdb196EE9DA8739",
      CHI_ETH_LP: "0x9f93F419d0267877247A39b4eb6b2775AbAC6bdc",
      USC_ETH_LP_Staking: "0x044bCdf7deA1a825B7be24573b738462a4FE9D3f",
      CHI_ETH_LP_Staking: "0x8f3871fD26Ac117f6E3D55E5f98E627Ca5d5e581",
      USC_ETH_LP_StakingOld: "0x85CD2803223C864D625b1D289fDD3Cee7e4bB307",
      CHI_ETH_LP_StakingOld: "0xD66DAbE0c898Ec74DA03AE4e90b9c051408685e6",
      USC_ETH_LP_Locking: "0x06Ad9F7DCF8DB10B1a39168e32ace2425a1F88aE",
      CHI_ETH_LP_Locking: "0x371a13Db03e929944AD61530F5bfc7a86cF98ff5",
      WST_USC_Locking: "0x96F3258e9c15EA33C82cd062220634df7Fb096B1", // todo: this is incorect

      ChiVesting: "0x426DBAa2B33cE1B833C13b72503F5128AFef79fC",
      USCStaking: "0x6Dd9738FB2277fcD6B2f5eb5FdaAaeC32e702761",
      USCStakingOld: "0x60aBb55c8488698153dB0AF2af362EdB25A505e3",
      WstUSC: "0xb7343ADda6b97BB1dE39b8d9dF2630cFBb963871",
      StakingManager: "0x1402452D1FF1066AcB48Aa2d5E4c0Ca81a8a6B16",
      ReserveHolder: "0xc36303ef9c780292755B5a9593Bfa8c1a7817E2a",
      ReserveHolderDataProvider: "0x72Fe0f6402eC84f2156f979c1b256F67B5A8B356",
      RewardController: "0xb9e08d13d2694B561a5033ACa07Ba7dEa49D0258",

      USC_ETH_LP_rewards: "0xc34ac319acAd3F8470a781921535856194726cb7",
      CHI_ETH_LP_rewards: "0x3CCC8E02eB810D84DA0107f0bD6Dd90eE76b46dE",
      OCHI: "0x8c3904bC848F6beff87c37F0414430a54B335Daa",
      Arbitrage: "0x594f4983Df88c3d84caA6eb30C18fBA1986ED6f1",
      veCHI: "0x237675D4DB33e505d1BB2Ed442B99Ab03886E2Fd",
      TestpageHelper: "0x088BDd5cEdc79043cdBCc8772ae224b0aeDf9B0C",
      Treasury: "0xA4b80F3ba55A4ed1316b173F64D5D036D7F83D4d",

      DataProvider: "0x1A387041Aa6660cD801B5c96AA1B4028a7d26Bd1",

      TimeWeightedBonding: "0x403f72d4A8F8b0779AfBC8bE2f69fB296e7f8DA3",
      WETH_ORACLE: "0x1213c98d9F3b28B313Ba5BDCc6261dBFc486bd75",
      StETH_ORACLE: "0x9Cf7fc9563Afc93BefE61CA810c1b440d1bD7323",
      USC_ORACLE: "0xdf9f3c6EB3357C8CE9f3706B9a6E430Ad593345b",
      CHI_ORACLE: "0xA2506b632af2f8e6131Fb1755F7A75F9160Ae949",
      IDO: "0x4794d2EAFf61cC414b8e077C2305396A550b1B83",
      // common
      stETH: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
      WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      Uniswapv2Router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      UniswapV2TwapOracle: "0xedAeDD22e653c504ff6806bf61664292848eB26e",
      UniswapV3Router: "0xe592427a0aece92de3edee1f18e0157c05861564",
      DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
      //ZAP
      USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      USDe: "0x4c9EDD5852cd905f086C759E8383e09bff1E68B3",
      FRAX: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
      ZAP: "0xD4cc670e076B6963882aFE63FF6142cBcbC156F9",

      CHI_LOCKING_APR: "0x6e46B9bf4a983A44787ce028698d86D472b8A1bA",
    };

import { contractAddresses, USCStakingAbi } from "@meta";
import { formatUnits } from "viem";
import { useReadContract } from "wagmi";

export const InfoCard = () => {
  // Emission Per Second
  const { data: emissionData, isFetched: isEmissionFetched } = useReadContract({
    abi: USCStakingAbi,
    address: contractAddresses.USCStaking,
    functionName: "emissionPerSecond",
    args: [],
  });

  // Start Timestamp
  const { data: startTimestampData, isFetched: isStartFetched } =
    useReadContract({
      abi: USCStakingAbi,
      address: contractAddresses.USCStaking,
      functionName: "startTimestamp",
      args: [],
    });

  // Owner
  const { data: ownerData, isFetched: isOwnerFetched } = useReadContract({
    abi: USCStakingAbi,
    address: contractAddresses.USCStaking,
    functionName: "owner",
    args: [],
  });

  // Total Staked
  const { data: totalStakedData, isFetched: isTotalStakedFetched } =
    useReadContract({
      abi: USCStakingAbi,
      address: contractAddresses.USCStaking,
      functionName: "totalStaked",
      args: [],
    });

  // Reward Per Share
  const { data: rewardPerShareData, isFetched: isRewardFetched } =
    useReadContract({
      abi: USCStakingAbi,
      address: contractAddresses.USCStaking,
      functionName: "rewardPerShare",
      args: [],
    });

  return (
    <div className="p-4 border rounded-lg  bg-gray-100">
      <h2 className="text-xl font-bold mb-4">stUSC Admin Dashboard</h2>
      <ul className="space-y-2">
        <li>
          <strong>Emission Per Second:</strong>{" "}
          {!isEmissionFetched
            ? "Loading..."
            : formatUnits(emissionData || 0n, 18)}
        </li>
        <li>
          <strong>Start Timestamp:</strong>{" "}
          {!isStartFetched
            ? "Loading..."
            : new Date(Number(startTimestampData) * 1000).toLocaleString()}
        </li>
        <li>
          <strong>Owner:</strong> {!isOwnerFetched ? "Loading..." : ownerData}
        </li>
        <li>
          <strong>Total Staked:</strong>{" "}
          {!isTotalStakedFetched
            ? "Loading..."
            : formatUnits(totalStakedData || 0n, 18)}
        </li>
        <li>
          <strong>Reward Per Share:</strong>{" "}
          {!isRewardFetched
            ? "Loading..."
            : formatUnits(rewardPerShareData || 0n, 18)}
        </li>
      </ul>
    </div>
  );
};

import { contractAddresses, USCStakingAbi } from "@meta";
import { DisplayableAmount } from "@shared";
import { useReadContract } from "wagmi";
import { formatWithDynamicPrecision } from "../../../utils";

export const useFetchUscStakingAPR = () => {
  const secondsPerYear = 365 * 24 * 60 * 60;

  const { data: emissionPerSecond, isFetched: isFetchedEmission } =
    useReadContract({
      abi: USCStakingAbi,
      address: contractAddresses.USCStaking,
      functionName: "emissionPerSecond",
    });

  const { data: totalSupply, isFetched: isFetchedSupply } = useReadContract({
    abi: USCStakingAbi,
    address: contractAddresses.USCStaking,
    functionName: "totalSupply",
  });

  const apr =
    emissionPerSecond && totalSupply
      ? (BigInt(emissionPerSecond) * BigInt(secondsPerYear) * 100n) /
        BigInt(totalSupply)
      : 0n;

  return {
    apr,
    isFetchedAPR: isFetchedEmission && isFetchedSupply,
    aprDSPL: {
      viewValue: formatWithDynamicPrecision(Number(apr), {}),
      isFetched: isFetchedEmission && isFetchedSupply,
      label: "APR",
    } as DisplayableAmount,
  };
};

import { ownerWhiteListedAddresses } from "@meta";
import { AccessControl } from "@shared";
import { PageContent } from "../components/PageContent/PageContent";
import { ClaimRewards } from "./components/ClaimRewards";
import { Rebalance } from "./components/Rebalance";
import { ReserveAssets } from "./components/ReserveAssets";
import { ReserveBreakdown } from "./components/ReserveBreakdown";
import { SetClaimer } from "./components/SetClaimer";
import { SetRebalancer } from "./components/SetRebalancer";
import { SetReserveAssetPercentage } from "./components/SetReserveAssetPercentage";
import { SetTwapTollerance } from "./components/SetTwapTollerance";

export default function ReserveHolderPage() {
  return (
    <AccessControl allowedAddresses={ownerWhiteListedAddresses}>
      <PageContent className="flex flex-col gap-4">
        <ReserveBreakdown />
        <ReserveAssets />
        <Rebalance />
        <ClaimRewards />
        <SetClaimer />
        <SetRebalancer />
        <SetTwapTollerance />
        <SetReserveAssetPercentage />
      </PageContent>
    </AccessControl>
  );
}

import {
  Card,
  DisplayMoney,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";

import stEthIcon from "@assets/tokens/stEth.svg";
import weETHIcon from "@assets/tokens/weEth.svg";
import wETHIcon from "@assets/tokens/weth.svg";
import { useReserveValue } from "../../../state/reserveholder/useOverviewData";

import uscIcon from "@assets/tokens/usc.svg";
import { contractAddresses } from "../../../../meta";
import { useReserveAssetByAddress } from "../../../state/reserveholder/useReserveAssetByAddres";
import { USCTotalSupplyChart } from "../charts/solvency/USCTotalSupplyChart";
import { SupplyVsReservesChart } from "../charts/solvency/SupplyVsReserves";

export const ReservesTab = () => {
  const reserveValue = useReserveValue();

  const { data: stEth, ...stEthRest } = useReserveAssetByAddress(
    contractAddresses.stETH
  );

  const { data: weETH, ...weETHRest } = useReserveAssetByAddress(
    contractAddresses.weETH
  );

  const { data: weTH, ...weTHRest } = useReserveAssetByAddress(
    contractAddresses.WETH
  );

  return (
    <div className="flex flex-col gap-8">
      <FlexRow className="flex-col xl:flex-row gap-8">
        <div className="w-full">
          <Card hasShadowInstead hasBorder>
            <FlexCol className="p-4 gap-6">
              <Typography type="body-small-medium">Reserves Stats</Typography>
              <FlexRow className="flex-col md:flex-row">
                <FlexCol className="w-full items-left xs:items-center md:w-1/2 justify-normal xs:justify-center   text-left xs:text-center">
                  <Typography
                    type="body-medium-regular"
                    className="text-neutral-black-60"
                  >
                    Total Reserves
                  </Typography>
                  <DisplayMoney {...reserveValue} typography="h3" />
                </FlexCol>
                <FlexRow className="flex-col xs:flex-row w-full md:w-1/2 justify-normal xs:justify-center items-left xs:items-center min-h-[244px] items-left xs:text-center gap-2 md:gap-10">
                  <FlexCol className="pt-8 pb-4 xs:py-8 xs:gap-2 justify-normal text-start items-start xs:justify-center xs:text-center xs:items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={stEthIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        stETH Reserves
                      </Typography>
                      {/* <StandardTooltip>?</StandardTooltip> */}
                    </FlexRow>
                    <DisplayMoney
                      {...stEthRest}
                      {...stEth.dollarAmount}
                      typography="body-large-bold"
                    />
                  </FlexCol>
                  <FlexCol className="py-4 xs:py-8  gap-2 justify-normal text-start items-start xs:justify-center xs:text-center xs:items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={weETHIcon} />
                      <Typography
                        className="text-neutral-black-60 whitespace-nowrap"
                        type="body-small-regular"
                      >
                        weETH Reserves
                      </Typography>
                      {/* <StandardTooltip>?</StandardTooltip> */}
                    </FlexRow>
                    <DisplayMoney
                      {...weETHRest}
                      {...weETH.dollarAmount}
                      typography="body-large-bold"
                    />
                  </FlexCol>
                  <FlexCol className="py-4 xs:py-8  gap-2 justify-normal text-start items-start xs:justify-center xs:text-center xs:items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={wETHIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        WETH Reserves
                      </Typography>
                    </FlexRow>
                    <DisplayMoney
                      {...weTHRest}
                      {...weTH.dollarAmount}
                      typography="body-large-bold"
                    />
                  </FlexCol>
                </FlexRow>
              </FlexRow>
            </FlexCol>
          </Card>
        </div>
      </FlexRow>

      <div className="flex flex-col gap-8 md:min-h-200px lg:min-h-[500px]">
        <Card hasShadowInstead className="w-full">
          <USCTotalSupplyChart
            name="USC Supply"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
        <Card hasShadowInstead className="w-full">
          <SupplyVsReservesChart
            name="USC Supply vs Reserves"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
        {/* <Card hasShadowInstead>
          <UscEthUniswapPoolReservesChart
            name="Usc/Eth Uniswap Pool Reserves"
            icon={<SVGWrapper width={34.6} height={34.6} src={uscEthIcon} />}
          />
        </Card> */}
      </div>
      {/* <div className="flex-grow">
        <Card hasShadowInstead>
          <UscUsdcUniswapPoolReservesChart name="Usc/Usdc Uniswap Pool Reserves" />
        </Card>
      </div> */}
    </div>
  );
};

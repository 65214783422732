import { Address, formatUnits, parseUnits } from "viem";
import { contractAddresses } from "@meta";
import { TOKEN_CONFIG } from "../../../../../../../../config/tokenConfig";
import { getTokenActualAddressBySymbolAndChainId } from "../../../../../../../../state/across/contractAddressesByChain";
import { QuoteResponse } from "../../../../../../../../state/across/getQuote";
import { MulticallHandler } from "../../../../../../../../state/across/spokePoolAddreses";

export const calculateUscValueAcross = async (
  selectedAsset: Address | undefined,
  selectedTokenPrice: any,
  mintBurnFee: bigint | undefined,
  firstInputValueDebounced: string,
  accrossChainId: number | undefined,
  fetchGetQuote: (params: any) => Promise<QuoteResponse>
) => {
  if (!selectedTokenPrice) {
    throw new Error("Token price not found");
  }
  if (!mintBurnFee) {
    throw new Error("Mint burn fee is not set");
  }

  try {
    if (firstInputValueDebounced) {
      const uscValue =
        (selectedTokenPrice || 0) *
        (Number(firstInputValueDebounced || 0) *
          (1 - Number(formatUnits(mintBurnFee || 0n, 4))));

      const quote =
        accrossChainId !== 1
          ? await fetchGetQuote({
              inputToken: getTokenActualAddressBySymbolAndChainId(
                selectedAsset ? TOKEN_CONFIG[selectedAsset]?.symbol : undefined,
                accrossChainId || 1
              ),
              outputToken: getTokenActualAddressBySymbolAndChainId(
                selectedAsset ? TOKEN_CONFIG[selectedAsset]?.symbol : undefined,
                1
              ),
              originChainId: accrossChainId || 1,
              destinationChainId: 1,
              amount: String(
                parseUnits(
                  String(firstInputValueDebounced),
                  TOKEN_CONFIG[selectedAsset as Address]?.decimals
                )
              ),
              recipient: MulticallHandler,
              inputAmount: String(
                parseUnits(
                  String(firstInputValueDebounced),
                  TOKEN_CONFIG[selectedAsset as Address]?.decimals
                )
              ),
              isEth: selectedAsset === contractAddresses.ETH,
              isAmountConsideredSmall: Number(uscValue || 0) < 40,
            })
          : undefined;

      const fee = quote
        ? Number(formatUnits(BigInt(quote.totalRelayFee?.total || 0), 18))
        : 0;

      const feeUSD = fee * (selectedTokenPrice || 0);

      return {
        quoteRes: quote,
        uscValue: String(uscValue - feeUSD),
        selectedTokenValue: Number(firstInputValueDebounced || 0) - fee,
      };
    } else {
      return {
        quoteRes: undefined,
        uscValue: "",
      };
    }
  } catch (error) {
    console.error(error);
    throw new Error(
      "Across error: Failed to calculate USC value. Amount too low."
    );
  }
};

import { AccessControl, FlexCol } from "@shared";
import { SetEmissionPerSecond } from "./SetEmissionPerSecond";
import { InfoCard } from "./InfoCard";
import { ownerWhiteListedAddresses } from "@meta";

export default function StUscManager() {
  return (
    <AccessControl allowedAddresses={ownerWhiteListedAddresses}>
      <FlexCol className="gap-10">
        <div className="w-2/3">
          <InfoCard />
        </div>
        <div className="w-2/3">
          <SetEmissionPerSecond />
        </div>
      </FlexCol>
    </AccessControl>
  );
}

import { contractAddresses, DataProviderAbi } from "@meta";
import { DisplayableInputField } from "@shared";
import { formatUnits } from "viem";
import { useReadContract } from "wagmi";

export const useFetchLpAPR = () => {
  const {
    data: apr,
    isFetched: isFetchedAPR,
    isLoading,
  } = useReadContract({
    abi: DataProviderAbi,
    functionName: "lpStakingApr",
    address: contractAddresses.DataProvider,
    args: [
      contractAddresses.CHI,
      contractAddresses.StakingManager,
      contractAddresses.PriceFeedAggregator,
      contractAddresses.USC_ETH_LP,
      contractAddresses.CHI_ETH_LP,
    ],
  });

  return {
    apr,
    isFetchedAPR: isFetchedAPR || !isLoading,
    uscEthLpAprDSPL: {
      value: formatUnits(apr?.[0] || 0n, 0),
      isFetched: isFetchedAPR || !isLoading,
      label: "APR",
    } as DisplayableInputField,
    chiEthLpAprDSPL: {
      value: formatUnits(apr?.[1] || 0n, 0),
      isFetched: isFetchedAPR || !isLoading,
      label: "APR",
    } as DisplayableInputField,
  };
};

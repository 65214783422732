import { useDebounce } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { formatUnits, parseUnits, Address } from "viem";
import { useAccount } from "wagmi";
import { contractAddresses } from "../../../../../../../../meta";
import { useNotificationContext } from "../../../../../../../../shared";
import { TOKEN_CONFIG } from "../../../../../../../config/tokenConfig";
import { getTokenActualAddressBySymbolAndChainId } from "../../../../../../../state/across/contractAddressesByChain";
import {
  QuoteResponse,
  useGetQuote,
} from "../../../../../../../state/across/getQuote";
import { MulticallHandler } from "../../../../../../../state/across/spokePoolAddreses";
import { useChainIdContext } from "../../../../../contexts/ChainIdContext";

export const useOnUscValueChange = (
  selectedAsset: Address | undefined,
  selectedTokenPrice: any,
  mintBurnFee: bigint | undefined,
  setIsTypingIn: (value?: "eth" | "usc" | undefined) => void,
  isTypingIn: string | undefined,
  setQuoteRes: (value?: QuoteResponse) => void,
  setFirstInputValue: (value: string) => void,
  uscValue: string,
  setUscValue: (value: string) => void
) => {
  const { showNotification } = useNotificationContext();
  const { fetchGetQuote } = useGetQuote();
  const { chainId: walletChain } = useAccount();
  const { chainId: accrossChainId } = useChainIdContext();
  const uscValueDebounced = useDebounce(uscValue, 750);

  const onUscValueChange = async (value: string) => {
    setUscValue(value);
    setIsTypingIn("usc");
  };

  useEffect(() => {
    const setUscValueAsync = async () => {
      const value = uscValueDebounced;

      if (!selectedTokenPrice) {
        setUscValue("");
        setFirstInputValue("");
        showNotification({
          status: "error",
          content: "Token price not found",
        });
        return;
      }
      if (!mintBurnFee) {
        setUscValue("");
        setFirstInputValue("");
        showNotification({
          status: "error",
          content: "Mint burn fee is not set",
        });
        return;
      }

      try {
        if (value) {
          const ethEquivalent =
            (Number(value) * (1 + Number(formatUnits(mintBurnFee || 0n, 4)))) /
            (selectedTokenPrice || 0);

          const quote =
            accrossChainId !== 1
              ? await fetchGetQuote({
                  inputToken: getTokenActualAddressBySymbolAndChainId(
                    selectedAsset
                      ? TOKEN_CONFIG[selectedAsset]?.symbol
                      : undefined,
                    accrossChainId || 1
                  ),
                  outputToken: contractAddresses.WETH,
                  originChainId: accrossChainId || 1,
                  destinationChainId: 1,
                  amount: String(
                    parseUnits(
                      String(ethEquivalent),
                      TOKEN_CONFIG[selectedAsset as Address]?.decimals
                    )
                  ),
                  recipient: MulticallHandler,
                  inputAmount: String(
                    parseUnits(
                      String(ethEquivalent),
                      TOKEN_CONFIG[selectedAsset as Address]?.decimals
                    )
                  ),
                  isEth: selectedAsset === contractAddresses.ETH,
                  isAmountConsideredSmall: Number(value || 0) < 40,
                })
              : undefined;
          setQuoteRes(quote);

          const fee = quote
            ? Number(formatUnits(BigInt(quote.totalRelayFee?.total || 0), 18))
            : 0;

          setFirstInputValue(String(ethEquivalent - fee));
        } else {
          setFirstInputValue("");
        }
      } catch (error) {
        console.error(error);
        setFirstInputValue("");
      }
    };

    if (isTypingIn !== "usc") return;
    else setIsTypingIn(undefined);

    setUscValueAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTokenPrice, mintBurnFee, uscValueDebounced]);

  useEffect(() => {
    setUscValue("");
  }, [walletChain]);

  return {
    onUscValueChange,
    uscValueDebounced,
  };
};

import "react-horizontal-scrolling-menu/dist/styles.css";
import "../../../../../index.css";
import {
  DisplayPercentage,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";
import { ScrollContainersProps } from "./ScrollContainers";
import { useStakeContext } from "../../../../../contexts/StakeProvider";

export const MobileScrollContainers: React.FC<ScrollContainersProps> = ({
  items,
}) => {
  return (
    <div className="overflow-y-scroll max-h-[700px] relative rounded-xl bg-neutral-black-30 p-3">
      <FlexCol className="gap-4">
        {items.map(
          ({
            id,
            title,
            apr,
            icon,
            subtitle,
            illustration,
            isFetched,
            stakedSelectedItem,
            boostSelectedItem,
            activeTab,
          }) => (
            <MobileScrollCard
              title={title}
              apr={apr}
              subtitle={subtitle}
              icon={icon}
              key={id}
              onClick={() => {}}
              selected={false}
              illustration={illustration}
              isFetched={isFetched}
              activeTab={activeTab}
              stakedSelectedItem={stakedSelectedItem}
              boostSelectedItem={boostSelectedItem}
            />
          )
        )}
      </FlexCol>
    </div>
  );
};

export function MobileScrollCard({
  selected,
  title,
  apr,
  subtitle,
  icon,
  illustration,
  isFetched,
  activeTab,
  stakedSelectedItem,
  boostSelectedItem,
}: {
  disabled?: boolean;
  onClick: Function;
  selected: boolean;
  title: string;
  apr: string;
  subtitle: string;
  icon: string;
  illustration: string;
  isFetched: boolean;
  activeTab: string;
  stakedSelectedItem: string;
  boostSelectedItem: string;
}) {
  const { setActiveTab, setStakeSelectedItem, setBoostSelectedItem } =
    useStakeContext();

  return (
    <FlexCol
      className={`rounded-md bg-white w-full min-h-[345px]  ${selected ? "bg-blue-200" : ""}`}
      tabIndex={0}
    >
      <FlexCol className="w-full h-[190px] rounded-md items-center mb-4 bg-neutral-black-10 justify-end">
        <SVGWrapper src={illustration} />
      </FlexCol>
      <FlexCol className="gap-2 p-4">
        <Typography
          type="body-medium-regular"
          className="text-neutral-black-60"
        >
          {title}
        </Typography>
        <FlexRow className="gap-1 items-start">
          <DisplayPercentage
            viewValue={apr}
            isFetched={isFetched}
            symbolPosition="after"
            typography="h5"
          ></DisplayPercentage>
          <span className="text-neutral-black-60 text-h5">APR</span>
        </FlexRow>
        <Typography type="caption-regular" className="text-neutral-black-50">
          {subtitle}
        </Typography>
        <FlexRow className="gap-2 items-start mt-4">
          <button
            className="w-[63px] h-[32px] rounded-md bg-primary"
            onClick={() => {
              setActiveTab(activeTab);
              setBoostSelectedItem(boostSelectedItem);
              setStakeSelectedItem(stakedSelectedItem);
            }}
          >
            <Typography type="caption-medium" className="text-white">
              View
            </Typography>
          </button>
          <SVGWrapper src={icon} width="24px" height="24px" />
        </FlexRow>
      </FlexCol>
    </FlexCol>
  );
}

import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { parseUnits, etherUnits, Address, formatUnits } from "viem";
import { useAccount } from "wagmi";
import { ArbitrageAbi, contractAddresses, ZAPAbi } from "@meta";
import {
  FlexCol,
  Typography,
  FlexRow,
  Button,
  useNotificationContext,
  DisplayableInputField,
  useWingsContractWrite,
  StandardNotifBody,
  ApproveComplete,
  DisplayErrorNotif,
  StepsContainer,
  Transfer,
  TransferMoney,
  InputField,
  SVGWrapper,
} from "@shared";
import { formatMoney, displayTokens, formatUnitsToMaxValue } from "@utils";
import { useButtonPropsOverride } from "../../../../../../state/across/buttonPropsOverride";
import { getTokenActualAddressBySymbolAndChainId } from "../../../../../../state/across/contractAddressesByChain";
import {
  generateMessageForMulticallHandler,
  depositV3,
} from "../../../../../../state/across/deposit";
import {
  QuoteResponse,
  useGetQuote,
} from "../../../../../../state/across/getQuote";
import {
  SpokePoolAddresses,
  MulticallHandler,
} from "../../../../../../state/across/spokePoolAddreses";
import {
  useEthersBalance,
  useEthersErc20Balance,
} from "../../../../../../state/erc20/useBalanceEthers";
import { WaitForDepositV3 } from "../../../../../../state/across/waitForDeposit";
import { useGetWalletBalanceByToken } from "../../../../../../state/erc20/useGetWalletBalanceByToken";
import { useChainIdContext } from "../../../../contexts/ChainIdContext";
import { getOptionsPerChainId } from "../../../../utils/getOptions2";
import { TOKEN_CONFIG } from "../../../../../../config/tokenConfig";
import { useERC20Approval } from "../../../../../../state/common/useERC20Approval.ethers";
import { useStepState } from "../../../../../../state/common/useStepState.ui";

/* ---------- */
/*   Icons   */
/* --------- */
import uscIcon from "@assets/tokens/usc.svg";
import stUSCLogo from "@assets/tokens/stUsc.svg";
import { successNotif } from "./notifications/onSuccessNotification";
import { loadingNotif } from "./notifications/onLoadingNotification";
import { useMintBurnFee } from "../../../../../../state/arbitrage/useMintBurnFee";
import { RouteComponent } from "../../route/RouteComponent";
import { AdditionalInfo } from "../../../additionalInfo/AdditionalInfo";
import { useQueryClient } from "@tanstack/react-query";
import { MintAssetPicker } from "../../../../../../components/dropdowns/MintAssetPicker";
import { useGetTokenPriceCrosschain } from "../../../../../../state/price/useGetTokenPriceCrosschain";
import { useOnFirstInputFieldChangeUnified } from "./state/useOnFirstInputFieldChange";
import { useZapinToken } from "../../../../../../state/1inch/zapin";
import { fetchGetSwapMetadata } from "../../../../../../state/zap/getCalldata";
import { useOnUscValueChange } from "./state/useOnUscValueChange";

export type MintType = "mint" | "mintAndStake" | "mintWithEthAndStake";

export const FormContainer: React.FC<{
  isMintAndStake: boolean;
  selectedAsset?: Address;
  setSelectedAsset: (asset?: Address) => void;
}> = ({ isMintAndStake, selectedAsset, setSelectedAsset }) => {
  /* --------- */
  /*   Other   */
  /* --------- */

  const [isZapin, setIsZapin] = useState(false);
  const [isBridging, setIsBridging] = useState(false);
  /* --------------- */
  /*    Meta data    */
  /* --------------- */
  const queryClient = useQueryClient();
  const { chainId: accrossChainId } = useChainIdContext();
  const { address, isConnected, chainId: walletChainId } = useAccount();

  /* ----------- */
  /*    Fetch    */
  /* ----------- */
  const { isLoading: isLoadingQute } = useGetQuote();
  const {
    data: { mintBurnFee },
  } = useMintBurnFee();

  const { formattedPrice: selectedTokenPrice, isFetched: isFormattedFetched } =
    useGetTokenPriceCrosschain(
      getTokenActualAddressBySymbolAndChainId(
        selectedAsset ? TOKEN_CONFIG[selectedAsset]?.symbol : undefined,
        walletChainId
      ),
      walletChainId
    );

  const { data: ETHBalance, isLoading: isETHBalanceLoading } =
    useEthersBalance(accrossChainId);

  const { data: erc20Balance } = useEthersErc20Balance(
    accrossChainId,
    selectedAsset
  );

  const { displayData: uscBalance, queryKey: uscBalanceQueryKey } =
    useGetWalletBalanceByToken(contractAddresses.USC, {});

  const { displayData: stUscBalance, queryKey: stUscBalanceQueryKey } =
    useGetWalletBalanceByToken(contractAddresses.USCStaking, {});
  /* ---------------- */
  /*   Local State    */
  /* ---------------- */
  const mintType: MintType = isMintAndStake
    ? selectedAsset === contractAddresses.ETH
      ? "mintWithEthAndStake"
      : "mintAndStake"
    : "mint";
  const [uscValue, setUscValue] = useState("");
  const [firstInputValue, setFirstInputValue] = useState("");
  const [isTypingIn, setIsTypingIn] = useState<"eth" | "usc" | undefined>(
    undefined
  );
  const [quoteRes, setQuoteRes] = useState<QuoteResponse | undefined>(
    undefined
  );

  const [isQuoteFetching, setIsQuoteFetching] = useState<boolean>(false);
  const { onEthValueChange } = useOnFirstInputFieldChangeUnified(
    selectedAsset,
    selectedTokenPrice,
    mintBurnFee,
    setIsTypingIn,
    isTypingIn,
    setQuoteRes,
    setUscValue,
    firstInputValue,
    setFirstInputValue,
    isZapin,
    isBridging,
    setIsQuoteFetching
  );
  const { onUscValueChange } = useOnUscValueChange(
    selectedAsset,
    selectedTokenPrice,
    mintBurnFee,
    setIsTypingIn,
    isTypingIn,
    setQuoteRes,
    setFirstInputValue,
    uscValue,
    setUscValue
  );

  /* ------------------ */
  /*    Side effects    */
  /* ------------------ */
  const options = useMemo(() => {
    const options = getOptionsPerChainId(accrossChainId as number, isMintAndStake);
    setSelectedAsset(options?.options?.[0]?.key);

    return options;
  }, [accrossChainId, isMintAndStake]);

  useEffect(() => {
    setUscValue("");
    setFirstInputValue("");
  }, [accrossChainId, isMintAndStake]);

  useEffect(() => {
    const config = TOKEN_CONFIG[selectedAsset as Address];
    setIsZapin(config?.isZappingIn || false);
  }, [selectedAsset]);

  useEffect(() => {
    setIsBridging(accrossChainId !== 1);
  }, [accrossChainId]);

  /* ------------- */
  /*    Methods    */
  /* ------------- */
  const { getButtonPropsOverride } = useButtonPropsOverride();
  const { showNotification } = useNotificationContext();
  const { writeContractAsync: mintAsync, isPending: isMinting } =
    useWingsContractWrite();

  const { isZaping, zapinAsync } = useZapinToken();

  const approveAddress =
    walletChainId === 1
      ? isZapin || mintType === "mintAndStake"
        ? contractAddresses.ZAP
        : contractAddresses.Arbitrage
      : (SpokePoolAddresses as any)[walletChainId || 1];

  const { approveAsync: approve, isApproved } = useERC20Approval(
    selectedAsset,
    approveAddress,
    parseUnits(
      firstInputValue,
      TOKEN_CONFIG[selectedAsset as Address]?.decimals
    ),
    walletChainId
  );

  const getButtonText = () => {
    if (Number(uscValue || 0) > 0 || Number(firstInputValue || 0) > 0) {
      if (!isApproved) return "Approve";
      if (isMintAndStake) return "Mint and Stake USC";

      return "Mint USC";
    } else {
      return "Enter amount";
    }
  };

  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Mint"],
    isApproved ? 1 : 0
  );

  const handleButtonClick = async () => {
    if (!isApproved) {
      loadingNotif(
        showNotification,
        firstInputValue,
        uscValue,
        selectedAsset,
        0,
        steps,
        undefined,
        isMintAndStake
      );

      await approve(
        parseUnits(
          firstInputValue,
          TOKEN_CONFIG[selectedAsset as Address]?.decimals
        ),
        {
          onSuccess: async (txHash) => {
            showNotification({
              txHash, // todo
              status: "success",
              content: (
                <StandardNotifBody
                  headerComponent={<ApproveComplete />}
                  transferComponent={
                    <Transfer
                      leftComponent={
                        <TransferMoney
                          icon={
                            <SVGWrapper
                              src={
                                selectedAsset
                                  ? TOKEN_CONFIG[selectedAsset].logo
                                  : ""
                              }
                              width={22}
                              height={22}
                            />
                          }
                          text={formatMoney(firstInputValue)}
                          symbol={
                            selectedAsset
                              ? TOKEN_CONFIG[selectedAsset].symbol
                              : ""
                          }
                        />
                      }
                      rightComponent={
                        <TransferMoney
                          icon={
                            <SVGWrapper src={uscIcon} width={22} height={22} />
                          }
                          text={formatMoney(uscValue)}
                          symbol="USC"
                        />
                      }
                    />
                  }
                  stepsComponent={
                    <StepsContainer
                      stepNames={steps}
                      currentStep={currentStep}
                      loading
                    />
                  }
                />
              ),
            });
            await mintAsyncWrapper();
          },
          onError: (error) => {
            showNotification({
              status: "error",
              content: <DisplayErrorNotif message={error?.message} />,
            });
          },
        }
      );
    } else {
      await mintAsyncWrapper();
    }
  };

  const mintAsyncWrapper = async () => {
    if (!selectedAsset) {
      showNotification({
        status: "error",
        content: "Asset not selected",
      });
      return;
    }

    setCurrentStep(1);

    loadingNotif(
      showNotification,
      firstInputValue,
      uscValue,
      selectedAsset,
      1,
      steps,
      isBridging ? (
        <FlexCol className="w-full items-center text-center mt-10">
          <Typography type="body-small-bold">
            Estimated transaction time is ≈2 min
          </Typography>
        </FlexCol>
      ) : undefined,
      isMintAndStake
    );

    if (isBridging) {
      if (!address || !quoteRes) return;
      try {
        //todo isZapin
        const amount = parseUnits(
          firstInputValue,
          TOKEN_CONFIG[selectedAsset as Address]?.decimals
        );
        const inputAmount = BigNumber.from(amount);
        const outputAmount = BigNumber.from(amount).sub(
          BigNumber.from(quoteRes.totalRelayFee.total)
        );

        const inputToken = getTokenActualAddressBySymbolAndChainId(
          TOKEN_CONFIG[selectedAsset]?.symbol,
          walletChainId || 1
        );

        const swapMetadata = isZapin
          ? await fetchGetSwapMetadata({
            chainId: 1,
            amount: parseUnits(
              firstInputValue || "0",
              TOKEN_CONFIG[selectedAsset].decimals
            ).toString(),
            srcToken: getTokenActualAddressBySymbolAndChainId(
              TOKEN_CONFIG[selectedAsset]?.symbol,
              1
            ),
            dstToken: contractAddresses.WETH,
            fromAddress: address || "",
            originAddress: address || "",
          })
          : undefined;

        const message = await generateMessageForMulticallHandler(
          contractAddresses.Arbitrage,
          outputAmount,
          //todo ?
          contractAddresses.WETH,
          address,
          isZapin
            ? getTokenActualAddressBySymbolAndChainId(
              TOKEN_CONFIG[selectedAsset]?.symbol,
              1
            )
            : undefined,
          getTokenActualAddressBySymbolAndChainId(
            TOKEN_CONFIG[selectedAsset]?.symbol,
            walletChainId || 1
          ),
          swapMetadata?.tx?.data as any
        );

        const fillDeadlineBuffer = 18000;
        const depositResponse = await depositV3({
          depositor: address,
          recipient: MulticallHandler,
          inputToken,
          inputAmount,
          outputAmount,
          destinationChainId: 1,
          originChainId: walletChainId || 1,
          exclusiveRelayer: quoteRes.exclusiveRelayer,
          quoteTimestamp: quoteRes.timestamp,
          fillDeadline: Math.round(Date.now() / 1000) + fillDeadlineBuffer,
          exclusivityDeadline: quoteRes.exclusivityDeadline,
          message,
          isEth: selectedAsset === contractAddresses.ETH,
        });
        const receipt = await depositResponse.wait();

        const response = await WaitForDepositV3(receipt, walletChainId || 1);

        successNotif(
          showNotification,
          uscValue,
          firstInputValue,
          response?.fillTx,
          selectedAsset,
          isMintAndStake
        );
      } catch (error: any) {
        console.log({ error });
        showNotification({
          status: "error",
          content: <DisplayErrorNotif message={error?.reason} />,
        });
      }
    } else if (!isBridging) {
      if (isZapin) {
        try {
          const res = await fetchGetSwapMetadata({
            chainId: 1,
            amount: parseUnits(
              firstInputValue || "0",
              TOKEN_CONFIG[selectedAsset].decimals
            ).toString(),
            srcToken: selectedAsset,
            dstToken: contractAddresses.WETH,
            fromAddress: contractAddresses.ZAP || "",
            originAddress: address || "",
          });

          await zapinAsync(
            {
              abi: ZAPAbi,
              address: contractAddresses.ZAP,
              functionName: isMintAndStake ? "zapMintStake" : "zapMint",
              args: isMintAndStake
                ? [
                  contractAddresses.Arbitrage,
                  selectedAsset,
                  contractAddresses.WETH,
                  parseUnits(
                    firstInputValue || "0",
                    TOKEN_CONFIG[selectedAsset].decimals
                  ),
                  address!,
                  contractAddresses.USCStaking,
                  contractAddresses.USC,
                  res?.tx?.data as any,
                ]
                : [
                  contractAddresses.Arbitrage,
                  selectedAsset,
                  contractAddresses.WETH,
                  parseUnits(
                    firstInputValue || "0",
                    TOKEN_CONFIG[selectedAsset].decimals
                  ),
                  address!,
                  res?.tx?.data as any,
                ],
            },
            {
              onSuccess: (txHash?: Address) => {
                setUscValue("");
                setFirstInputValue("");
                finishSteps();
                successNotif(
                  showNotification,
                  uscValue,
                  firstInputValue,
                  txHash,
                  selectedAsset,
                  isMintAndStake
                );
              },
            }
          );
        } catch (error: any) {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={error?.message} />,
          });
        }
      } else {
        const calculatedValue = parseUnits(
          String(firstInputValue),
          TOKEN_CONFIG[selectedAsset].decimals
        );
        const value =
          selectedAsset === contractAddresses.ETH &&
            (mintType === "mint" || mintType === "mintWithEthAndStake")
            ? calculatedValue
            : undefined;

        let args: any[] = [];
        if (mintType === "mint") {
          args =
            selectedAsset === contractAddresses.ETH
              ? [address]
              : [
                selectedAsset,
                parseUnits(
                  String(firstInputValue),
                  TOKEN_CONFIG[selectedAsset as Address]?.decimals
                ),
                address,
              ];
        } else if (mintType === "mintAndStake") {
          args = [
            contractAddresses.Arbitrage,
            selectedAsset,
            calculatedValue,
            address,
            contractAddresses.USCStaking,
            contractAddresses.USC,
          ];
        } else if (mintType === "mintWithEthAndStake") {
          args = [
            contractAddresses.Arbitrage,
            address,
            contractAddresses.USCStaking,
            contractAddresses.USC,
          ];
        }

        await mintAsync(
          {
            abi: isMintAndStake ? ZAPAbi : ArbitrageAbi,
            address: isMintAndStake
              ? contractAddresses.ZAP
              : contractAddresses.Arbitrage,
            functionName: mintType,
            args: args as any,
            value: value,
          },
          {
            onSuccess: (txHash?: Address) => {
              setUscValue("");
              setFirstInputValue("");
              finishSteps();
              successNotif(
                showNotification,
                uscValue,
                firstInputValue,
                txHash,
                selectedAsset,
                isMintAndStake
              );
              queryClient.resetQueries({
                queryKey: uscBalanceQueryKey,
              });
              queryClient.invalidateQueries({
                queryKey: stUscBalanceQueryKey,
              });
              queryClient.resetQueries({
                queryKey: stUscBalanceQueryKey,
              });
            },
          }
        );
      }
    }
    queryClient.invalidateQueries();
    queryClient.resetQueries();
  };

  const displayableWalletBalance: DisplayableInputField = {
    value:
      selectedAsset === contractAddresses.ETH
        ? ETHBalance.viewValue
        : erc20Balance.viewValue,
    isFetched: !isETHBalanceLoading,
    label: "",
  };

  const displayableDollarValue: DisplayableInputField = {
    value: displayTokens(
      BigInt(parseUnits(firstInputValue || "0", etherUnits.wei)),
      {
        displayInDollars: true,
        formattedPrice: selectedTokenPrice,
        hideTokenAmount: isFormattedFetched,
      }
    ),
    isFetched: !isETHBalanceLoading && isFormattedFetched,
    label: "",
  };
  const displayableUscDollarValue: DisplayableInputField = {
    value: formatMoney(Number(uscValue)),
    isFetched: isFormattedFetched,
  };

  return (
    <FlexCol className="gap-6">
      <div>
        <InputField
          name="ETHAmount"
          onChange={(e) => onEthValueChange(e.target.value)}
          value={firstInputValue}
          label={`Deposit ${selectedAsset ? TOKEN_CONFIG[selectedAsset].symbol : ""
            }:`}
          placeholder="Amount"
          disabled={isTypingIn === "usc"}
          rightLabel={
            <MintAssetPicker
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
              options={options}
            />
          }
          bottomRightMax={
            <button
              disabled={
                isETHBalanceLoading ||
                !isConnected ||
                accrossChainId !== walletChainId
              }
              className="text-caption-regular text-primary"
              onClick={() => {
                onEthValueChange(
                  selectedAsset === contractAddresses.ETH
                    ? formatUnits(ETHBalance.originalValue || 0n, 18)
                    : formatUnitsToMaxValue(
                      erc20Balance.originalValue || 0n,
                      selectedAsset
                        ? TOKEN_CONFIG[selectedAsset].decimals || 18
                        : 18
                    )
                );
              }}
            >
              MAX
            </button>
          }
          dollarValue={displayableDollarValue}
          walletBalance={displayableWalletBalance}
        />
      </div>

      <div>
        <InputField
          name="USCAmount"
          onChange={(e) => onUscValueChange(e.target.value)}
          value={uscValue}
          label="To Receive:"
          placeholder="Amount"
          disabled={
            isTypingIn === "eth" ||
            (walletChainId !== undefined && 1 !== walletChainId)
          }
          leftLabel={
            <FlexRow className="items-center gap-2">
              <SVGWrapper
                src={isMintAndStake ? stUSCLogo : uscIcon}
                width={28}
                height={28}
              />
            </FlexRow>
          }
          dollarValue={displayableUscDollarValue}
          walletBalance={isMintAndStake ? stUscBalance : uscBalance}
        />
      </div>

      <Button
        onClick={handleButtonClick}
        disabled={
          !isConnected ||
          (!uscValue && !firstInputValue) ||
          isTypingIn !== undefined
        }
        loading={isMinting || isLoadingQute || isQuoteFetching || isZaping}
        fullWidth
        size="big"
        color="primary"
        {...getButtonPropsOverride(accrossChainId)}
      >
        {getButtonText()}
      </Button>

      <FlexCol>
        <AdditionalInfo selectedAsset={selectedAsset} />
        <FlexRow className="w-full py-3 items-center justify-between">
          <Typography type="caption-regular">Route</Typography>
          <RouteComponent
            selectedAsset={selectedAsset}
            isZapin={isZapin}
            isMintAndStake={isMintAndStake}
            chainId={accrossChainId}
          />
        </FlexRow>
      </FlexCol>
    </FlexCol>
  );
};

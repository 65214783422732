import { Address, formatUnits } from "viem";
import { formatUnitsToMoney, formatMoney } from "../../../utils";
import { PriceFeedAggregatorAbi, contractAddresses } from "../../../meta";
import { readContractQueryOptions, useQuery } from "wagmi/query";
import { getQueryClient } from "../../../contexts/CustomQueryClientProvider";
import { getWagmiConfig } from "../../config/wagmi.config";
import { TOKEN_CONFIG } from "../../config/tokenConfig";
import { IS_DEV_MODE } from "../../../globals";
import { priceQueryConfig } from "../../../shared";
import { fetchPriceFromCoinGecko } from "../price/useGetCoingeckoPriceByAddress";

export interface TokenPrice {
  priceInDollars: string;
  originalPrice?: bigint;
  formattedPrice: number | undefined;
  formattedMoneyPrice: string;
  decimals: number;
}

const fetchPriceFromPriceFeedAggregator = async (
  address: Address
): Promise<TokenPrice> => {
  const config = getWagmiConfig();
  const queryClient = getQueryClient();
  const finalAddress =
    address === contractAddresses.ETH ? contractAddresses.WETH : address;

  const data = await queryClient.fetchQuery({
    ...readContractQueryOptions(config, {
      abi: PriceFeedAggregatorAbi,
      address: contractAddresses.PriceFeedAggregator,
      functionName: "peek",
      args: [finalAddress],
    }),
    ...priceQueryConfig,
  });
  // Extract originalPrice and decimals from the data
  const originalPrice = data as bigint;
  // const decimals = (data as any)[1];
  const decimals = 8;

  // Calculate price in dollars
  const priceInDollars = formatUnitsToMoney(originalPrice, { decimals }) || "";

  // Format original price
  const formattedPrice = originalPrice
    ? parseFloat(formatUnits(originalPrice, decimals))
    : undefined;

  const formattedMoneyPrice = originalPrice
    ? formatMoney(parseFloat(formatUnits(originalPrice, decimals)))
    : "0";

  return {
    priceInDollars,
    originalPrice,
    formattedPrice,
    formattedMoneyPrice,
    decimals,
  };
};

/**
 * Use this custom hook to get the price from the PriceFeedAggregator.
 *
 * @param address - The contract address to query for the price.
 * @returns An object containing price details or undefined if data is not available.
 */
export const useGetPriceByAddress = (
  address?: Address,
  chainId: number = 1
): {
  priceInDollars: string;
  originalPrice?: bigint;
  formattedPrice: number | undefined;
  formattedMoneyPrice: string | undefined;
  decimals: number;
  displayData: {
    value: string | undefined;
    isFetched: boolean;
    isLoading: boolean;
  };
  viewValue: string;
  isFetched: boolean;
} => {
  const assetConfig = TOKEN_CONFIG[address!];
  const useCoingecko = assetConfig?.useCoingeckoForPrice;

  const { data, isFetched, isLoading } = useQuery({
    queryKey: ["hookPriceByAddress", address],
    queryFn: () =>
      useCoingecko
        ? fetchPriceFromCoinGecko(address!, chainId)
        : fetchPriceFromPriceFeedAggregator(address!),
    enabled: !!address,
    staleTime: IS_DEV_MODE ? Infinity : 600 * 1000,
  });

  const typedData = data as TokenPrice | undefined;

  if (!typedData) {
    return {
      priceInDollars: "",
      originalPrice: undefined,
      formattedPrice: undefined,
      formattedMoneyPrice: undefined,
      decimals: 0,
      displayData: {
        value: undefined,
        isFetched,
        isLoading,
      },
      viewValue: "",
      isFetched,
    };
  }

  const displayData = {
    value: typedData?.formattedMoneyPrice,
    isFetched,
    isLoading,
  };

  return {
    ...typedData,
    displayData,
    viewValue: formatMoney(typedData?.formattedPrice || "0"),
    isFetched,
  };
};
